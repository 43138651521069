import React, { useState, useEffect } from "react";

// components
import { SelectFilter } from "../_commons/SelectFilter";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import Popover from "../_commons/Popover";
import TableColumnSelector from "../_commons/TableColumnSelector";
import Placeholder from "../_commons/Placeholder";
import { ButtonIcon } from "../_commons/ButtonIcon";

// third party
import { connect } from "react-redux";
import moment from "moment";
import Highlighter from "react-highlight-words";

// store
import { store } from "../../store/configureStore";

// utils
import { trackEvent } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchReportsExportHistory } from "../../actions/reports";

// constants
import { REPORTS_GROUP_MAP } from "./Overview";
const columns = [
	{
		name: "Report Name",
		field: "report-name",
		render: (record, i, rest) => (
			<div className="table-cell report-name" key={i}>
				<Highlighter
					className="name"
					highlightClassName="highlight"
					searchWords={[rest.searchKeyword]}
					autoEscape={true}
					textToHighlight={record?.report?.name || "--"}
				/>
				<div className="category">{REPORTS_GROUP_MAP[record?.report?.group]?.name || "--"}</div>
			</div>
		)
	},
	{
		name: "Downloaded On",
		field: "downloaded-on",
		render: (record, i, rest) => (
			<div className="table-cell downloaded-on" key={i}>
				<div>{moment(record.createdAt)?.format("DD MMM, YYYY - hh:mm A") || "--"}</div>
				{rest?.appliedFilters?.["downloaded_by"]?.value === "all" && (
					<div className="user" title={record?.requestedUserEmail || record?.requestedUser?.fullName || null}>
						{record?.requestedUserEmail
							? `By: ${record?.requestedUserEmail}`
							: record?.requestedUser?.fullName
							? `By: ${record?.requestedUser?.fullName}`
							: ""}
					</div>
				)}
			</div>
		)
	},
	{
		name: "Filters Applied",
		field: "filters-applied",
		render: (record, i, rest) => {
			const showMore =
				record.filtersApplied?.length > 2 ||
				record.filtersApplied?.[0]?.values?.length > 4 ||
				record.filtersApplied?.[1]?.values?.length > 4 ||
				false;
			return (
				<div className="table-cell filters-applied" key={i}>
					{record?.filtersApplied?.slice(0, 2)?.map((filter, j) => (
						<div title={getAppliedFilter(filter, true)} className="filter" key={j}>
							<span>{filter.title}:</span>
							{getAppliedFilter(filter, true)}
						</div>
					))}
					{showMore && (
						<Popover
							data={record}
							showOnHover={false}
							showOnClick={true}
							renderPopover={rest.handleFiltersPopover}
							position="down-right"
						>
							<div className="more">+ more</div>
						</Popover>
					)}
				</div>
			);
		}
	},
	{
		name: "Exported To",
		field: "exported-to",
		render: (record, i, rest) => (
			<div className="table-cell exported-to" key={i}>
				<div>
					<span>Format:</span>
					{(record.exportFormat || "--")?.toUpperCase()}
				</div>
				{record.requestedForEmails?.length > 0 &&
					record.requestedForEmails?.slice(0, 1)?.map((email, j) => (
						<div key={j} className="email" title={email}>
							{email}
						</div>
					))}
				{record.requestedForEmails?.length > 1 && (
					<Popover
						data={record}
						showOnHover={false}
						showOnClick={true}
						renderPopover={rest.handleEmailsPopover}
						position="down-right"
					>
						<div className="more">+ more</div>
					</Popover>
				)}
			</div>
		)
	},
	{
		name: "Size",
		field: "size",
		render: (record, i) => (
			<div className="table-cell size" key={i}>
				{record.reportSize
					? record.reportSize >= 1000
						? `${parseFloat((record.reportSize / 1000).toFixed(1))} MB`
						: `${parseFloat(record.reportSize.toFixed(1))} KB`
					: "--"}
			</div>
		)
	},
	{
		name: "Access Report",
		field: "access-report",
		render: (record, i, rest) => (
			<div className="table-cell access-report" key={i}>
				<div
					className="download"
					title={`Download ${record?.exportFormat?.toUpperCase()}`}
					onClick={() => rest.handleDownloadReport(record)}
				>
					<div>Download</div>
					<ButtonIcon icon="download" color="#AAAAAA" />
				</div>
			</div>
		)
	}
];
const PLACEHOLDER = {
	placeholderText: "No Data Available",
	placeholderImageUrl: "/assets/empty_states/graphics-empty-reports.svg",
	placeholderSubtext:
		"Data is not available with the chosen filters. Please make adjustments to the filters in order to view your reports.",
	size: "medium"
};

const ExportReportHistory = ({ exportReportHistory, reportCategoriesList, searchKeyword = "", access = {} }) => {
	const [isLoading, setIsLoading] = useState(false);
	const {
		loading,
		limit,
		offset,
		currPage,
		prevId,
		nextId,
		data,
		tableColumnsSelected,
		currentFilters,
		appliedFilters,
		currentDateFilter,
		appliedDateFilter
	} = exportReportHistory;

	useEffect(() => {
		fetchReportsExportHistory(searchKeyword);
	}, [appliedFilters, appliedDateFilter, prevId, nextId, offset, searchKeyword]);

	useEffect(() => {
		// track event
		trackEvent("export_report_history_list_view", {});
	}, []);

	const updateExportReportHistoryState = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_EXPORT_REPORT_HISTORY,
			payload
		});
	};

	const handleFilters = (field, value) => {
		const updatedFilters = { ...currentFilters };
		updatedFilters[field] = value;
		updateExportReportHistoryState({
			currentFilters: updatedFilters,
			appliedFilters: updatedFilters,
			offset: 0,
			currPage: 1,
			prevId: "",
			nextId: ""
		});
	};

	const handlePagination = async (action) => {
		// set new offset based on action
		let newCurrPage = undefined;
		let newPrevId = "";
		let newNextId = "";
		switch (action) {
			case "first":
				newCurrPage = 1;
				newPrevId = "";
				newNextId = "";
				break;
			case "prev":
				newCurrPage = currPage - 1;
				newPrevId = data.objects?.[0]?.reportId;
				newNextId = "";
				break;
			case "next":
				newCurrPage = currPage + 1;
				newPrevId = "";
				newNextId = data.objects?.slice(-1)?.[0]?.reportId;
				break;
			default:
				break;
		}
		updateExportReportHistoryState({
			offset: (newCurrPage - 1) * limit,
			currPage: newCurrPage,
			prevId: newPrevId,
			nextId: newNextId
		});
	};

	const handlePageSize = async (field, size) => {
		// set new limit
		if (size && size?.value !== limit) {
			updateExportReportHistoryState({
				[field]: size.value,
				offset: 0,
				currPage: 1,
				prevId: "",
				nextId: ""
			});
		}
	};

	const handleDownloadReport = (record) => {
		// download report
		if (record?.downloadLink) {
			setIsLoading(true);
			const link = document.createElement("a");
			link.href = record?.downloadLink;
			link.click();
		}
		setTimeout(() => {
			setIsLoading(false);
		}, 3000);

		// track event
		trackEvent("report_download", {
			report_category: REPORTS_GROUP_MAP[record?.report?.group]?.name || "",
			report_name: record?.report?.name || "",
			report_format: record.exportFormat?.toUpperCase() || "",
			report_emails: record?.requestedForEmails?.length || 0,
			download_src: "recent_exports"
		});
	};

	const handleFiltersPopover = (record) => {
		return (
			<div className="filters-applied-popover">
				<div className="header">{record?.report?.name}</div>
				<div className="filters-list">
					{record.filtersApplied?.map((filter, i) => (
						<div className="filter-row" key={i}>
							<div className="name">{filter.title}</div>
							<div className="value">{getAppliedFilter(filter, false)}</div>
						</div>
					))}
				</div>
			</div>
		);
	};

	const handleEmailsPopover = (record) => {
		return (
			<div className="emails-popover">
				<div className="header">{record?.report?.name}</div>
				<div className="emails-list">
					<div className="email-row">
						<div className="name">Format</div>
						<div className="value">{(record.exportFormat || "--")?.toUpperCase()}</div>
					</div>
					<div className="email-row">
						<div className="name">Shared to</div>
						<div className="value">
							{getAppliedFilter({ type: "multiple", values: record.requestedForEmails }, true)}
						</div>
					</div>
				</div>
			</div>
		);
	};

	const handleColumnSelection = (isSelected, field, optKey) => {
		updateExportReportHistoryState({
			tableColumnsSelected: {
				[field]: {
					...tableColumnsSelected[field],
					[optKey.field]: isSelected
				}
			}
		});
	};

	return (
		<div className="export-report-history">
			<div className="filters-container">
				<div className="left">
					<SelectFilter
						options={
							reportCategoriesList?.length > 0
								? [
										{ valueForDisplay: "All", value: "all" },
										...(reportCategoriesList.map((cat) => ({
											valueForDisplay: REPORTS_GROUP_MAP[cat.reportGroup]?.name,
											value: cat.reportGroup
										})) ?? [])
								  ]
								: [{ valueForDisplay: "All", value: "all" }]
						}
						currValue={currentFilters.group}
						field="group"
						setFilter={handleFilters}
						isClearable={false}
						customDropdownLabel={
							currentFilters.group ? (
								<div className="custom-value">
									Category <span>{currentFilters.group["valueForDisplay"]}</span>
								</div>
							) : null
						}
						labelKey="valueForDisplay"
						valueKey="value"
						placeholder="Select category"
						classes="category"
					/>
					{access.isAdmin && (
						<SelectFilter
							options={data?.filters?.find((filter) => filter.field === "downloaded_by")?.values || []}
							currValue={currentFilters?.["downloaded_by"] || null}
							field="downloaded_by"
							setFilter={handleFilters}
							isClearable={false}
							customDropdownLabel={
								currentFilters?.["downloaded_by"] ? (
									<div className="custom-value">
										Downloaded by{" "}
										<span>{currentFilters?.["downloaded_by"]?.["valueForDisplay"]}</span>
									</div>
								) : null
							}
							labelKey="valueForDisplay"
							valueKey="value"
							placeholder="Select downloaded by"
							classes="downloaded-by"
						/>
					)}
				</div>
				<div className="right">
					<NewDateCompareFilter
						showDropdown={true}
						hideComparison={true}
						includeAllTime={true}
						loading={loading}
						currentDateFilter={currentDateFilter}
						appliedDateFilter={appliedDateFilter}
						updateState={(payload) =>
							updateExportReportHistoryState({
								...payload,
								offset: 0,
								currPage: 1,
								prevId: "",
								nextId: ""
							})
						}
						position="right"
					/>
					<TableColumnSelector
						options={columns?.slice(1, 5) || []}
						field="columns"
						labelKey="name"
						valueKey="field"
						handleColumnSelection={handleColumnSelection}
						selectedColumns={tableColumnsSelected}
					/>
				</div>
			</div>
			<CustomTable
				bordered
				columns={columns?.filter((col) => tableColumnsSelected.columns[col.field]) || []}
				loading={loading || isLoading}
				data={data?.objects || []}
				handleFiltersPopover={handleFiltersPopover}
				handleEmailsPopover={handleEmailsPopover}
				handleDownloadReport={handleDownloadReport}
				appliedFilters={appliedFilters}
				searchKeyword={searchKeyword}
				classes="export-report-history-table-container"
				customPlaceholder={<Placeholder {...PLACEHOLDER} />}
			/>
			<Paginator
				limit={limit}
				offset={offset}
				currPage={currPage}
				count={100} // random number above 10 to show/render paginator component, using pagination similar to Audit Events (Activity History)
				goToPage={handlePagination}
				setPageSize={handlePageSize}
				showPageSize={true}
				showTotalPages={false}
				restrictPagination={true}
				loading={loading}
				hasNext={data.hasMore}
				hasPrevious={data.hasPrevious}
			/>
		</div>
	);
};
export default connect((store) => ({
	access: store.login.loginDetail.access,
	exportReportHistory: store.exportReportHistory,
	reportCategoriesList: store.reportsNewList.data
}))(ExportReportHistory);

export const getAppliedFilter = (filter, ellipsized = false) => {
	if (filter.type === "multiple") {
		let value = "";
		if (ellipsized) {
			filter.values.slice(0, 4).forEach((val, i) => {
				if (i === filter?.values?.slice(0, 4)?.length - 1) {
					value += val + (filter?.values?.length > 4 ? "..." : "");
				} else {
					value += `${val}, `;
				}
			});
		} else {
			filter.values.forEach((val, i) => {
				if (i === filter.values?.length - 1) {
					value += value ? `and ${val}` : val;
				} else {
					value += `${val}, `;
				}
			});
		}
		return value;
	}

	if (filter.type === "date") {
		const dates = filter.values[0].split(",");
		let value = "";
		if (dates[0] === dates[1]) {
			value = moment(dates[0]).format("DD MMM, YYYY");
		} else {
			value = `${moment(dates[0]).format("DD MMM, YYYY")} - ${moment(dates[1]).format("DD MMM, YYYY")}`;
		}
		return value;
	}

	return "";
};
