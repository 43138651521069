import gql from "graphql-tag";

export const GET_DSP_PLATFORMS_LIST = gql`
	query getDspPlatformsList($limit: Int, $offset: Int) {
		dspPlatformList(limit: $limit, offset: $offset) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				platform
				bizId
				isExpired
				activeStoresCount
				mappedStoresCount
				unmappedStoresCount
				syncStatus
				lastSynchedAt
			}
		}
	}
`;

export const SET_DSP_PLATFORM_AUTH = gql`
	mutation setDspPlatformAuth($platform: String!, $authCode: String!) {
		dspPlatformAuth(input: { platform: $platform, authCode: $authCode }) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				platform
				bizId
				isExpired
				activeStoresCount
				mappedStoresCount
				unmappedStoresCount
				syncStatus
				lastSynchedAt
			}
		}
	}
`;

export const GET_DSP_PLATFORM_LOCATIONS_LIST = gql`
	query getDspPlatformLocationsList(
		$platform: String!
		$limit: Int
		$offset: Int
		$search: [SearchArgument]
		$filters: [ListFilterArgument]
	) {
		dspPlatformLocationList(
			platform: $platform
			limit: $limit
			offset: $offset
			search: $search
			filters: $filters
		) {
			count
			activeStoresCount
			mappedStoresCount
			unmappedStoresCount
			assignedLocationIds
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				externalId
				externalUrl
				locationName
				associatedMenu {
					menuId
					name
				}
				storeStatus
				platformName
				bizPlatform {
					id
					platformName
				}
				location {
					id
					name
					brand {
						id
						name
					}
					associatedBrandCount
					associatedBrandsData {
						id
						name
					}
				}
				dspStatus
				dspSuccess
				dspErrorMessage
			}
		}
	}
`;

export const GET_LOCATIONS = gql`
	query getStores($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput, $brand: String) {
		stores(limit: $limit, offset: $offset, filters: $filters, sort: $sort, brand: $brand) {
			count

			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				bizLocationId
				name
				associatedPlatforms {
					externalId
					outletUrl
					platformName
					state
					verificationStatus
					menu {
						menuId
						name
					}
				}
			}
		}
	}
`;

export const DSP_UPDATE_PLATFORM_LOCATIONS = gql`
	mutation dspUpdatePlatformLocations($platform: String!, $locations: [PlatformLocationInput]!) {
		dspUpdatePlatformLocations(input: { platform: $platform, locations: $locations }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const UPDATE_LOCATION_PLATFORM_ASSOCIATIONS = gql`
	mutation saveLocationsPlatform($data: [LocationPlatformNonReqAssociationInput]) {
		saveLocationPlatformAssociations(input: { data: $data }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const DSP_ACTIVATE_STORES_PUBLISH_MENU = gql`
	mutation dspActivateStoresAndPublishMenu($platform: String!, $locationMenus: [LocationMenuInput]!) {
		dspActivateStoresAndPublishMenu(input: { platform: $platform, locationMenus: $locationMenus }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const DSP_AGGREGATED_STATUS = gql`
	query dspAggregatedStatus($platform: String!) {
		dspAggregratedStatus(platform: $platform) {
			objects {
				status
				completed
				total
				iconState
			}
		}
	}
`;

export const DSP_PLATFORM_SWITCH_ACCOUNT = gql`
	mutation dspPlatformSwichAccount($platform: String!) {
		dspPlatformLogout(input: { platform: $platform }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;
