import React from "react";

// components
import Header from "./Header";
import ReportFilters from "./ReportFilters";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";

const SelectFilters = ({
	reportDetail,
	scheduleReportCreate,
	isFormTouched = false,
	setFormTouched,
	isMultibrandEnabled = false
}) => {
	const { loading, data, currentFilters, validations, optionUpdates, isCheckedAll } = reportDetail;
	const { scheduleReportData } = scheduleReportCreate;

	const updateReportDetail = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload
		});
	};

	const setFilter = (field, value) => {
		const filters = {
			...currentFilters,
			[field]: value
		};
		if (field === "showDataFor") {
			const scheduleForFilter = scheduleReportData?.scheduleReportFilters?.find((f) => f.field === "scheduleFor");
			filters["scheduleFor"] =
				value.value === "Previous Day"
					? scheduleForFilter?.values?.[0]
					: value.value === "Last 7 Days"
					? scheduleForFilter?.values?.[1]
					: scheduleForFilter?.values?.[2];
		}
		updateReportDetail({
			currentFilters: filters,
			appliedFilters: filters,
			validations: {
				...validations,
				[field]: ""
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleCheck = (state, field, option, props) => {
		const updates = {
			...optionUpdates,
			[field]: {
				...optionUpdates[field],
				[option[props.valueKey]]: state
			}
		};
		const isCheckedAllUpdates = {
			...isCheckedAll,
			[field]: false
		};
		let updatedLocationsList = [...(data.filters.find((f) => f.field === "locations")?.values ?? [])];
		// custom logic to handle locations filter for multi brand biz
		if (field === "brands") {
			if (state) {
				updatedLocationsList = [
					...updatedLocationsList,
					...data.locationsList.filter((loc) => loc.brandId === option[props.valueKey])
				];
			} else {
				updatedLocationsList = updatedLocationsList.filter((location) => {
					if (location.brandId === option[props.valueKey]) {
						updates.locations = {
							...(updates.locations ?? {}),
							[location.value]: false
						};
					}
					return location.brandId !== option[props.valueKey];
				});
			}
			isCheckedAllUpdates["locations"] = false;
		}
		updateReportDetail({
			data: {
				...data,
				filters: data.filters.map((f) => {
					if (f.field === "locations") {
						f.values = updatedLocationsList;
					}
					return f;
				})
			},
			optionUpdates: updates,
			isCheckedAll: isCheckedAllUpdates,
			validations: {
				...validations,
				[field]: ""
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleCheckAll = (state, field, options, props) => {
		const filterOptions = {};
		options.forEach((opt) => {
			filterOptions[opt[props.valueKey]] = state;
		});
		const updates = {
			...optionUpdates,
			[field]: filterOptions
		};
		const isCheckedAllUpdates = {
			...isCheckedAll,
			[field]: state
		};
		let updatedLocationsList = [...(data.filters.find((f) => f.field === "locations")?.values ?? [])];
		if (field === "brands") {
			if (state) {
				updatedLocationsList = data.locationsList;
				isCheckedAllUpdates["locations"] = false;
			} else {
				updates["locations"] = {};
				isCheckedAllUpdates["locations"] = state;
				updatedLocationsList = [];
			}
		}
		updateReportDetail({
			data: {
				...data,
				filters: data.filters.map((f) => {
					if (f.field === "locations") {
						f.values = updatedLocationsList;
					}
					return f;
				})
			},
			optionUpdates: updates,
			isCheckedAll: isCheckedAllUpdates,
			validations: {
				...validations,
				[field]: ""
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	return (
		<div className="select-report-filters">
			<Header loading={loading} title={data?.name || ""} description={data?.description || ""} />
			{!data?.filters?.length && loading ? (
				<div className="filters-container">
					<div className="shimmer W(330px) H(60px)"></div>
					<div className="shimmer W(330px) H(60px)"></div>
					<div className="shimmer W(330px) H(60px)"></div>
					<div className="shimmer W(330px) H(60px)"></div>
				</div>
			) : (
				<div className="filters-container">
					<ReportFilters
						options={[
							...(scheduleReportData?.scheduleReportFilters?.filter((f) => f.field === "showDataFor") ??
								[]),
							...(data?.filters ?? [])
						]}
						filtersToDisplay={["showDataFor", ...(data.filters?.map((f) => f.field) ?? [])]}
						setFilter={setFilter}
						currentFilters={currentFilters}
						optionUpdates={optionUpdates}
						handleCheck={handleCheck}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						validations={validations}
						showFields={true}
						isMultibrandEnabled={isMultibrandEnabled}
					/>
				</div>
			)}
		</div>
	);
};
export default SelectFilters;
