import React, { useState, useCallback, useRef } from "react";
// client
import { client } from "../client";

// store
import { store } from "../store/configureStore";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// graphql
import {
	GET_DSP_PLATFORMS_LIST,
	SET_DSP_PLATFORM_AUTH,
	GET_DSP_PLATFORM_LOCATIONS_LIST,
	GET_LOCATIONS,
	DSP_UPDATE_PLATFORM_LOCATIONS,
	UPDATE_LOCATION_PLATFORM_ASSOCIATIONS,
	DSP_ACTIVATE_STORES_PUBLISH_MENU,
	DSP_AGGREGATED_STATUS,
	DSP_PLATFORM_SWITCH_ACCOUNT
} from "../graphql/dspIntegration";

// actions
import { ActionTypes } from "./_types";
import { toggleGlobalLoader } from "./actions";
import { PLATFORM_MAP } from "../components/Hub/Integrations";

export const fetchDspPlatformsList = async (currDsp = undefined) => {
	const { limit, offset } = store.getState().dspPlatformsList;
	store.dispatch({
		type: ActionTypes.UPDATE_DSP_PLATFORMS_LIST_STATE,
		payload: {
			isLoading: true
		}
	});
	try {
		const resp = await client.query({
			query: GET_DSP_PLATFORMS_LIST,
			variables: {
				limit,
				offset
			},
			fetchPolicy: "no-cache"
		});
		if (currDsp) {
			const selectedDsp =
				resp?.data?.dspPlatformList?.objects?.find((dsp) => dsp?.platform?.toLowerCase() === currDsp) ||
				undefined;
			store.dispatch({
				type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
				payload: {
					isDSPIntegrated: !selectedDsp?.isExpired,
					isValidDSP: !!selectedDsp,
					platform: selectedDsp?.platform?.toLowerCase()
				}
			});
		}
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_PLATFORMS_LIST_STATE,
			payload: {
				isLoading: false,
				...resp?.data?.dspPlatformList
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_PLATFORMS_LIST_STATE,
			payload: {
				isLoading: false
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const handleDSPAuthorization = async (authCode, platform) => {
	try {
		const resp = await client.mutate({
			mutation: SET_DSP_PLATFORM_AUTH,
			variables: {
				platform,
				authCode
			}
		});
		return resp?.data?.dspPlatformAuth;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchDspPlatformLocationsList = async (getStoresAssigned = false) => {
	const {
		limit,
		offset,
		platform,
		appliedFilters,
		searchFieldSelected,
		searchFieldValue,
		mappedLocations,
		data,
		brand
	} = store.getState().dspIntegration;
	store.dispatch({
		type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const variables = {
			platform,
			limit,
			offset
		};
		// filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (appliedFilters[f]?.value && appliedFilters[f]?.value !== "all") {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]?.value
				});
			}
		});
		// get all UP store assigned DSP locations only, this is shown in menu association step
		if (getStoresAssigned) {
			filtersObject.push({
				field: "is_store_assigned",
				value: true
			});
		}
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: GET_DSP_PLATFORM_LOCATIONS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		// identify the mapped locations (ones which are already associated)
		let updatedMappedLocations = { ...mappedLocations };
		if (
			Object.keys(updatedMappedLocations)?.length === 0 &&
			resp?.data?.dspPlatformLocationList?.assignedLocationIds?.length > 0
		) {
			resp.data.dspPlatformLocationList.assignedLocationIds.forEach((id) => {
				updatedMappedLocations[id] = true;
			});
		}
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false,
				data: {
					...(resp.data.dspPlatformLocationList ?? {}),
					objects:
						resp.data.dspPlatformLocationList?.objects?.map((loc) => ({
							...loc,
							isStoreMapped: !!loc?.location
						})) || [],
					storesCount:
						appliedFilters["store_status"]?.value === "all"
							? resp.data.dspPlatformLocationList?.count
							: data?.storesCount
				},
				mappedLocations: updatedMappedLocations
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchLocationsList = async () => {
	const {
		brandId,
		platform,
		limit,
		offset,
		appliedFilters,
		searchFieldSelected,
		searchFieldValue,
		mappedLocations,
		data
	} = store.getState().dspIntegration;
	store.dispatch({
		type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const variables = {
			brandId,
			platform,
			limit,
			offset
		};
		console.log(variables, "?????????????????????????????????????????");

		// filters
		let filtersObject = [];
		Object.keys(appliedFilters).forEach((f) => {
			if (appliedFilters[f]?.value && appliedFilters[f]?.value !== "all") {
				filtersObject.push({
					field: f,
					value: appliedFilters[f]?.value
				});
			}
		});
		variables.filters = filtersObject;

		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}

		const resp = await client.query({
			query: GET_LOCATIONS, //Add this query
			variables,
			fetchPolicy: "no-cache"
		});

		// identify the mapped locations (ones which are already associated)
		let updatedMappedLocations = { ...mappedLocations };
		if (Object.keys(updatedMappedLocations)?.length === 0 && resp?.data?.stores?.objects?.length > 0) {
			resp.data.stores.objects.forEach((loc) => {
				updatedMappedLocations[loc.bizLocationId] = true;
			});
		}

		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false,
				data: {
					...(resp.data.stores ?? {}),
					objects:
						resp.data.stores?.objects?.map((loc) => ({
							...loc,
							isStoreMapped: !!loc?.associatedPlatforms?.find(
								(platform) => platform.platformName === platform
							)
						})) || [],
					storesCount:
						appliedFilters["store_status"]?.value === "all" ? resp.data.stores?.limit : data?.storesCount
				},
				mappedLocations: updatedMappedLocations
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const handleDspUpdatePlatformLocations = async () => {
	const { platform, associationUpdates, externalUrl } = store.getState().dspIntegration;
	console.log(associationUpdates);

	store.dispatch({
		type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const variables = {
			platform,
			locations: Object.values(associationUpdates)
				?.filter((loc) => loc?.location !== null)
				?.map((loc) => ({
					externalId: loc?.externalId,
					locationId: loc?.location?.id || null
				})),
			externalUrl
		};
		const resp = await client.mutate({
			mutation: DSP_UPDATE_PLATFORM_LOCATIONS,
			variables
		});
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false
			}
		});
		return resp?.data?.dspUpdatePlatformLocations?.status;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const handleUpdatePlatformLocations = async () => {
	const { platform, associationUpdates, externalUrl } = store.getState().dspIntegration;
	store.dispatch({
		type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const variables = {
			platform,
			locations: Object.values(associationUpdates)
				?.filter((loc) => loc?.location !== null)
				?.map((loc) => ({
					externalId: loc?.externalId,
					locationId: loc?.location?.id || null
				})),
			externalUrl
		};
		const resp = await client.mutate({
			mutation: UPDATE_LOCATION_PLATFORM_ASSOCIATIONS,
			variables
		});
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false
			}
		});
		return resp?.data?.saveLocationPlatformAssociations?.status;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const handleDspActivateStoresAndPublishMenu = async (filterLocations = false, failedLocationIds = []) => {
	const { platform, associationUpdates } = store.getState().dspIntegration;
	store.dispatch({
		type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const variables = {
			platform,
			locationMenus: Object.values(associationUpdates)
				?.filter(
					(loc) =>
						loc?.associatedMenu !== null &&
						(filterLocations ? failedLocationIds?.includes(loc?.location?.id) : true)
				)
				?.map((loc) => ({
					locationId: loc?.location?.id || null,
					menuId: loc?.associatedMenu?.menuId || null
				}))
		};
		const resp = await client.mutate({
			mutation: DSP_ACTIVATE_STORES_PUBLISH_MENU,
			variables
		});
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false
			}
		});
		return resp?.data?.dspActivateStoresAndPublishMenu?.status;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchDspAggregatedStatus = async (platform) => {
	if (!platform) {
		return;
	}
	store.dispatch({
		type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
		payload: {
			loading: true
		}
	});
	try {
		const resp = await client.query({
			query: DSP_AGGREGATED_STATUS,
			variables: { platform },
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false,
				aggregatedStatus: resp?.data?.dspAggregratedStatus?.objects
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				loading: false
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const handleDspPlatformSwitchAccount = async () => {
	const { platform } = store.getState().dspIntegration;
	try {
		const variables = { platform };
		const resp = await client.mutate({
			mutation: DSP_PLATFORM_SWITCH_ACCOUNT,
			variables
		});
		return resp?.data?.dspPlatformLogout?.status;
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};
