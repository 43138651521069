import React, { Component } from "react";

// components
import { Header } from "../components/ItemsList/Header";
import { CommonTable } from "../components/_commons/CommonTable";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import Image from "../components/_commons/Image";
import MonogramGenerator from "../components/_commons/MonogramGenerator";
import ArchiveRestoreModal from "../components/_commons/ArchiveRestoreModal";
import CreateIcon from "../components/_commons/CreateIcon";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";

// actions
import { fetchBrands, fetchStoresDebounced } from "../actions/actions";
import { fetchItemsList } from "../actions/items";
import { ActionTypes } from "../actions/_types";

// utils
import {
	scroll,
	printCurrency,
	adjustNestedContainer,
	fixedToTwoDecimal,
	extractInitials,
	isFeatureFlagEnabled
} from "../atlas-utils";
import history from "../history";
import { TRACK_EVENT } from "../atlas-utils/tracking";

// constant
import { NESTED_ENTITY_TYPES } from "../client-config";

//graphql
import { client } from "../client";
import StatusBox from "../components/_commons/MenuStatusBox";
import ImportMenuDrawer from "../components/MenusList/ImportMenuDrawer";
import { getMenuPullInfo } from "../actions/menuPull";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Items",
		field: "items",
		sortKey: "title",
		render: (record, i, archived, cs, handleTask, checkbox, rest) => (
			<div
				className={`at-table-cell at-cell-text items ${
					rest.lastColumnIndex === i ? "at-table-cell--last-child" : ""
				}`}
				style={rest.appliedInlineStyles ? rest.appliedInlineStyles : {}}
				title={record.itemTitle}
				key={i}
			>
				<div className="item-image">
					<img
						src={record.imageUrl || "/assets/empty-dish.jpg"}
						alt="item image"
						onError={(e) => (e.target.src = "/assets/empty-dish.jpg")}
					/>
				</div>
				<div className="item-desc">
					<Link className={"hyperlink hyperlink--black-color " + archived} to={`/items/edit/${record.id}`}>
						{record.itemTitle || record.id}
					</Link>
					<div className="text--light item_tags">
						{record.tags.length > 0 && (
							<div className="tags-container table-mode">
								<div className="list">
									{record.tags.map(
										(tag, i) =>
											i < 3 && (
												<span key={i} className="tag-item" title={tag}>
													{tag}
												</span>
											)
									)}
									{record.tags.length > 3 && (
										<span className="tag-item more-tags" title={record.tags.slice(3).join(", ")}>
											+{record.tags.length - 3} more
										</span>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	},
	{
		name: "CRM Title",
		field: "crm-title",
		render: (record, i, ...rest) => (
			<div
				className={`at-table-cell at-cell-text crm-title ${
					rest[rest.length - 1].lastColumnIndex === i ? "at-table-cell--last-child" : ""
				}`}
				key={i}
				style={rest[rest.length - 1]?.appliedInlineStyles ? rest[rest.length - 1]?.appliedInlineStyles : {}}
			>
				<div>{record.crmTitle || "--"}</div>
				<div className="text--light desc-text">
					{record.merchantRefId && Number(record.merchantRefId) !== -1
						? `POS ID: ${record.merchantRefId}`
						: ""}
				</div>
			</div>
		)
	},
	{
		name: "Category",
		field: "category",
		sortKey: "item_category_order",
		render: (record, i, ...rest) => (
			<div
				className={`at-table-cell at-cell-text category ${
					rest[rest.length - 1].lastColumnIndex === i ? "at-table-cell--last-child" : ""
				}`}
				key={i}
				style={rest[rest.length - 1]?.appliedInlineStyles ? rest[rest.length - 1]?.appliedInlineStyles : {}}
			>
				{record.category ? (
					<div>
						<div
							className={
								record.itemDiscreteCategory && !record.itemDiscreteCategory.isActive
									? "category-container"
									: ""
							}
						>
							<span>{record.category}</span>
							{record.itemDiscreteCategory && !record.itemDiscreteCategory.isActive && (
								<img src="/assets/icons/icon-red-alert.svg" alt="" />
							)}
						</div>
						{record.itemDiscreteCategory && !record.itemDiscreteCategory.isActive && (
							<div className="category-desc">Archived category</div>
						)}
					</div>
				) : (
					<div className="category-container">
						<span>No Category</span>
						<img src="/assets/icons/icon-red-alert.svg" alt="" />
					</div>
				)}
			</div>
		)
	},
	{
		name: "Associated Locations",
		field: "associated_locations",
		sortKey: "related_locations_count",
		render: (record, i, ...rest) => (
			<div
				className={`at-table-cell at-cell-text associated_locations ${
					rest[rest.length - 1].lastColumnIndex === i ? "at-table-cell--last-child" : ""
				}`}
				key={i}
				style={rest[rest.length - 1]?.appliedInlineStyles ? rest[rest.length - 1]?.appliedInlineStyles : {}}
			>
				{record.associatedLocationsCount}
			</div>
		)
	},
	{
		name: "Associated Brands",
		field: "associated_brands",
		// sortKey: 'related_locations_count',
		render: (record, i, ...rest) => (
			<div
				className={`at-table-cell at-cell-text associated_brands ${
					rest[rest.length - 1].lastColumnIndex === i ? "at-table-cell--last-child" : ""
				}`}
				key={i}
				style={rest[rest.length - 1]?.appliedInlineStyles ? rest[rest.length - 1]?.appliedInlineStyles : {}}
			>
				{(!record?.brandsData || record?.brandsData?.length == 0) && <div className="brand-container">--</div>}
				{record?.brandsData?.map(
					(brand) =>
						!!brand.id && (
							<div className="brand-container" title={brand.name}>
								{!!brand.image ? (
									<Image src={brand.image} className="brand-img" alt={brand.name} />
								) : (
									<MonogramGenerator size="small" value={brand.name} />
								)}
							</div>
						)
				)}
			</div>
		)
	},
	{
		name: "Price",
		field: "price",
		sortKey: "item_price",
		render: (record, i, a, currencySymbol, ...rest) => (
			<div
				className={`at-table-cell at-cell-text price ${
					rest.lastColumnIndex === i ? "at-table-cell--last-child" : ""
				}`}
				key={i}
				style={rest[rest.length - 1]?.appliedInlineStyles ? rest[rest.length - 1]?.appliedInlineStyles : {}}
			>
				{record.markupPrice > record.itemPrice && (
					<span className="markup-price">
						{printCurrency(currencySymbol)}
						{fixedToTwoDecimal(record.markupPrice) || 0}
					</span>
				)}
				<span>
					{printCurrency(currencySymbol)}
					{fixedToTwoDecimal(record.itemPrice) || 0}
				</span>
			</div>
		)
	}
];
const menu_columns = [
	{
		name: "Items",
		field: "items",
		sortKey: "title",
		render: (record, i, archived, cs, handleTask, checkbox, rest) => (
			<div
				className={`at-table-cell at-cell-text items ${
					rest.lastColumnIndex === i ? "at-table-cell--last-child" : ""
				}`}
				style={rest.appliedInlineStyles ? rest.appliedInlineStyles : {}}
				title={record.itemTitle}
				key={i}
			>
				<div className="item-image">
					<img
						src={record.imageUrl || "/assets/empty-dish.jpg"}
						alt="item image"
						onError={(e) => (e.target.src = "/assets/empty-dish.jpg")}
					/>
				</div>
				<div className="item-desc">
					<Link className={"hyperlink hyperlink--black-color " + archived} to={`/items/edit/${record.id}`}>
						{record.itemTitle || record.id}
					</Link>
					<div className="text--light item_tags">
						{record.tags.length > 0 && (
							<div className="tags-container table-mode">
								<div className="list">
									{record.tags.map(
										(tag, i) =>
											i < 3 && (
												<span key={i} className="tag-item" title={tag}>
													{tag}
												</span>
											)
									)}
									{record.tags.length > 3 && (
										<span className="tag-item more-tags" title={record.tags.slice(3).join(", ")}>
											+{record.tags.length - 3} more
										</span>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	},
	{
		name: "CRM Title",
		field: "crm-title",
		render: (record, i, ...rest) => (
			<div
				className={`at-table-cell at-cell-text crm-title ${
					rest[rest.length - 1].lastColumnIndex === i ? "at-table-cell--last-child" : ""
				}`}
				key={i}
				style={rest[rest.length - 1]?.appliedInlineStyles ? rest[rest.length - 1]?.appliedInlineStyles : {}}
			>
				<div>{record.crmTitle || "--"}</div>
				<div className="text--light desc-text">
					{record.merchantRefId && Number(record.merchantRefId) !== -1
						? `POS ID: ${record.merchantRefId}`
						: ""}
				</div>
			</div>
		)
	},
	{
		name: "Price",
		field: "price",
		sortKey: "item_price",
		render: (record, i, a, currencySymbol, ...rest) => (
			<div
				className={`at-table-cell at-cell-text price ${
					rest.lastColumnIndex === i ? "at-table-cell--last-child" : ""
				}`}
				key={i}
				style={rest[rest.length - 1]?.appliedInlineStyles ? rest[rest.length - 1]?.appliedInlineStyles : {}}
			>
				{record.markupPrice > record.itemPrice && (
					<span className="markup-price">
						{printCurrency(currencySymbol)}
						{fixedToTwoDecimal(record.markupPrice) || 0}
					</span>
				)}
				<span>
					{printCurrency(currencySymbol)}
					{fixedToTwoDecimal(record.itemPrice) || 0}
				</span>
			</div>
		)
	}
];

const TABLE_FIELDS = [
	{
		value: "items",
		valueForDisplay: "Items",
		readOnly: true
	},
	{
		value: "crm-title",
		valueForDisplay: "CRM Title",
		readOnly: true
	},
	{
		value: "category",
		valueForDisplay: "Category",
		readOnly: true
	},
	{
		valueForDisplay: "Associated Locations",
		value: "associated_locations"
	},
	{
		value: "associated_brands",
		valueForDisplay: "Associated Brands"
	},
	{
		value: "price",
		valueForDisplay: "Price"
	}
];
const MENU_TABLE_FIELDS = [
	{
		value: "items",
		valueForDisplay: "Items",
		readOnly: true
	},
	{
		value: "crm-title",
		valueForDisplay: "CRM Title",
		readOnly: true
	},
	{
		value: "price",
		valueForDisplay: "Price"
	}
];

const ARCHIVE_RESTORE_MODAL_INIT_STATE = {
	isOpen: false,
	data: null
};

@connect((store) => ({
	itemsList: store.itemsList,
	itemsListState: store.itemsListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access,
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	menusPullInfoState: store.menusPullInfoState,
	loginDetail: store.login.loginDetail
}))
export class ItemsList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			archivedFilter: false,
			nestedEntity: NESTED_ENTITY_INITIAL_STATE,
			tableColumnsSelected: {
				tableColumnsSelected: {
					items: true,
					"crm-title": true,
					category: true
				}
			},
			contextMenuData: {},
			contextMenu: { id: null },
			archiveRestoreModalState: ARCHIVE_RESTORE_MODAL_INIT_STATE,
			isOpenMenuPullDrawer: false,
			showMenuPullStatus: true,
			isInternalUser: !!props.loginDetail?.email?.includes("@urbanpiper.com")
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "items_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		const { appliedFilters } = this.props.itemsListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		}

		const { configItems, brands } = this.props;

		// fetch Items list
		if (this.props.isMultibrandEnabled) {
			const brand = this.props.brands.selectedBrand
				? this.props.brands.selectedBrand
				: { id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" };
			store.dispatch({
				type: ActionTypes.UPDATE_SELECTED_BRAND,
				payload: brand
			});
			await fetchItemsList(brand?.id);
		} else {
			await fetchItemsList();
		}

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });

		// the below code preloads the stores dropdown
		if (!configItems.stores.items.length) {
			fetchStoresDebounced("");
		}

		if (this.props.isMultibrandEnabled) {
			fetchBrands("", true);
		}

		if (this.props.biz?.eisEnabled && this.state.isInternalUser) {
			getMenuPullInfo();
		}
	}

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "items"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updateitemsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_ITEMS_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updateitemsListState({
			currentFilters: this.props.itemsListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.itemsListState.currentFilters
		};
		currentFilters[field] = value;
		this.updateitemsListState({
			currentFilters
		});
	};

	handleSearchField = (field, value) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_ITEMS_LIST_SEARCH,
			payload: { [field]: value }
		});
	};

	setSearchFilter = (field, value) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_ITEMS_LIST_SEARCH,
			payload: { [field]: value }
		});
		this.updateitemsListState({
			offset: 0
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => fetchItemsList(this.props.brands?.selectedBrand?.id), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updateitemsListState({
			appliedFilters: {
				...this.props.itemsListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "items_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchItemsList(this.props.brands?.selectedBrand?.id);

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().itemsListState;
		if (appliedFilters["is_enabled"]) {
			this.setState({ archivedFilter: true });
		} else {
			this.setState({ archivedFilter: false });
		}
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			async () => {
				this.updateitemsListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				await fetchItemsList(this.props.brands?.selectedBrand?.id);
				this.setState({ archivedFilter: false });
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.itemsListState;
		const offset = (page - 1) * limit;
		this.updateitemsListState({
			offset
		});
		// fetch new Items list
		fetchItemsList(this.props.brands?.selectedBrand?.id);
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.itemsListState;
		if (size && size?.value !== limit) {
			this.updateitemsListState({
				[field]: size.value
			});
			// fetch new Items list
			await fetchItemsList(this.props.brands?.selectedBrand?.id);
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updateitemsListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.CATALOGUE_ITEMS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchItemsList(this.props.brands?.selectedBrand?.id);
	};

	updateStoresLookup = (id, title) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_ITEMS_LIST_UPDATE_STORES_LOOKUP,
			payload: {
				...this.props.itemsListState?.storesLookup,
				[id]: title
			}
		});
	};

	handleColumnSelection = (isSelected, field, optKey) => {
		this.setState({
			tableColumnsSelected: {
				tableColumnsSelected: {
					...this.state.tableColumnsSelected.tableColumnsSelected,
					[optKey.value]: isSelected
				}
			}
		});
	};

	handleBrand = async (brand) => {
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_BRAND,
			payload: brand
		});
		fetchItemsList(brand ? String(brand?.id) : null);
	};

	openContextMenu = (record = {}) => {
		this.setState({
			contextMenu: {
				id: this.state.contextMenu.id && this.state.contextMenu.id === record.id ? null : record.id,
				brand:
					this.state.contextMenu.id && this.state.contextMenu.id === record.id
						? null
						: this.state.selectedBrand,
				platform:
					this.state.contextMenu.id && this.state.contextMenu.id === record.id
						? null
						: this.state.selectedPlatform
			},
			contextMenuData: { ...record }
		});
	};

	closeArchiveRestoreModal = () => {
		this.setState({
			archiveRestoreModalState: ARCHIVE_RESTORE_MODAL_INIT_STATE
		});
	};

	openArchiveRestoreModal = (data) => {
		this.setState({
			archiveRestoreModalState: {
				isOpen: true,
				data: data
			}
		});
	};

	renderMenuItems = (record) => {
		return (
			<React.Fragment>
				<div
					className="action-item"
					onClick={() => this.openArchiveRestoreModal(record)}
					style={{ color: record.isEnabled ? "#ff425c" : "#2ecc71" }}
				>
					{record.isEnabled ? "Archive" : "Restore"}
				</div>
			</React.Fragment>
		);
	};

	closeContextMenu = () => {
		this.setState({
			contextMenu: {
				id: null,
				brand: null,
				platform: null
			},
			contextMenuData: {}
		});
	};
	handleMenuPullDrawer = () => {
		this.setState({ isOpenMenuPullDrawer: true });
	};
	handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	render() {
		const { itemsList, itemsListState, configItems, access, brands, isMultibrandEnabled, biz, menusPullInfoState } =
			this.props;
		const { limit, offset, currentFilters, sortedField } = itemsListState;

		const { tableColumnsSelected, isOpenMenuPullDrawer, isInternalUser } = this.state;

		const { ongoingMenuPull, canPullMenu, canPullLocationMenu, menuPullPOS } = menusPullInfoState;

		const isMenuEnabled = biz?.isMenuOverCatalogueEnabled || false;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "title") {
				if (f === "is_enabled" && currentFilters[f]) {
					filterCount++;
				} else if (currentFilters[f]?.value && currentFilters[f]?.value != "") {
					filterCount++;
				}
			}
		}

		const filterOptions = isMenuEnabled
			? itemsList.data.filters
					.map((f) => {
						if (f.type === "MULTIPLE" && f.field === "related_location_associations__location") {
							f = {
								...f,
								hide: true
							};
						}
						return f;
					})
					.filter((f) => f.field !== "title" || f.field !== "related_location_associations__location")
			: itemsList.data.filters
					.map((f) => {
						if (f.type === "MULTIPLE" && f.field === "related_location_associations__location") {
							f = {
								...f,
								isAsync: true,
								asyncOptions: this.props.configItems.stores,
								asyncLookup: this.props.itemsListState?.storesLookup,
								updateAsyncLookup: this.updateStoresLookup,
								handleAsyncSearch: fetchStoresDebounced,
								labelKey: "name",
								valueKey: "id"
							};
						}
						return f;
					})
					.filter((f) => f.field !== "title");

		const filteredColumns = isMenuEnabled
			? menu_columns.filter((column) => tableColumnsSelected.tableColumnsSelected[column.field])
			: columns.filter((column) => tableColumnsSelected.tableColumnsSelected[column.field]);
		const columnWidth = Math.floor(90 / (filteredColumns.length === 0 ? 1 : filteredColumns.length + 1));

		const placeholderContent = {
			placeholderText: "No items created yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-catalogue.svg",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Create New</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/items/new");
			},
			redirectionLink: "/piper-academy/items",
			redirectionLinkText: "learn more about items",
			size: "medium"
		};

		const requiredFilters = filterOptions.filter((filter) => filter.field !== "item_discrete_category_id");
		const categoryFilter = filterOptions.filter((filter) => filter.field === "item_discrete_category_id");
		const getTableFields = () => {
			if (isMenuEnabled) {
				return MENU_TABLE_FIELDS;
			} else if (!isMultibrandEnabled) {
				return TABLE_FIELDS.filter((field) => field.value !== "associated_brands");
			} else {
				return TABLE_FIELDS;
			}
		};

		return (
			<div className="items-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
				{configItems.dimensions.width > 768 && (
					<Filters
						isOpen={this.state.showFilters}
						close={this.filterSidebarCloseHandler}
						apply={this.applyFilters}
						clear={this.clearFilters}
						options={requiredFilters}
						currentFilters={currentFilters}
						setFilter={this.setFilter}
					/>
				)}
				<Header
					filterCount={filterCount}
					flipShowFilters={this.flipShowFilters}
					filterActive={this.state.showFilters}
					dimensions={configItems.dimensions}
					searchKeywords={itemsList.data.searchKeywords}
					searchFieldSelected={itemsList.data.searchFieldSelected}
					searchFieldValue={itemsList.data.searchFieldValue}
					handleSearchField={this.handleSearchField}
					setFilter={this.setSearchFilter}
					applySearchFilter={this.applySearchFilter}
					handlePiperAcademy={this.handlePiperAcademy}
					isCatalogueManagement={access.isCatalogueManagement}
					tableFields={getTableFields()}
					selectedColumns={tableColumnsSelected}
					handleColumnSelection={this.handleColumnSelection}
					brands={brands}
					handleBrand={this.handleBrand}
					selectedBrand={brands.selectedBrand}
					categoryFilter={categoryFilter}
					isMultibrandEnabled={isMultibrandEnabled}
					isMenuEnabled={isMenuEnabled}
					handleMenuPullDrawer={this.handleMenuPullDrawer}
					showImport={
						(isInternalUser && canPullMenu) || (isFeatureFlagEnabled("menu-pull-from-pos") && canPullMenu)
					}
					handleBrandsLabelOption={this.handleBrandsLabelOption}
					ongoingMenuPull={ongoingMenuPull}
				/>

				{isOpenMenuPullDrawer && (
					<ImportMenuDrawer
						handleClose={() => this.setState({ isOpenMenuPullDrawer: false })}
						isOpen={isOpenMenuPullDrawer}
						isMultibrandEnabled={isMultibrandEnabled}
						brands={brands}
						handleBrandsLabelOption={this.handleBrandsLabelOption}
						canPullLocationMenu={canPullLocationMenu}
						pos={menuPullPOS}
						ongoingMenuPull={ongoingMenuPull}
					/>
				)}

				<CommonTable
					loading={itemsList.loading}
					data={itemsList.data.objects || []}
					columns={filteredColumns}
					sortList={this.sortList}
					sortedField={sortedField}
					currencySymbol={this.props.biz.currencySymbol}
					archived={this.state.archivedFilter ? "archived" : ""}
					classes="items-list-table-container"
					content="Items"
					lastColumnIndex={filteredColumns.length - 1}
					appliedInlineStyles={{ width: `${columnWidth}%` }}
					openContextMenu={this.openContextMenu}
					closeContextMenu={this.closeContextMenu}
					contextMenuId={this.state.contextMenu.id}
					renderMenuItems={this.renderMenuItems}
					showContextMenu
					showPlaceholder
					placeholderContent={placeholderContent}
				/>
				<Paginator
					limit={limit}
					offset={offset}
					count={itemsList.data.count || 0}
					goToPage={this.handlePagination}
					setPageSize={this.handlePageSize}
					showPageSize={true}
				/>
				<NestedEntityContainer
					show={this.state.nestedEntity.show}
					type={this.state.nestedEntity.type}
					id={this.state.nestedEntity.id}
					closeNestedContainer={() => this.handleNestedEntity(false)}
					nestedRef={this.nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
				<ArchiveRestoreModal
					isOpen={this.state.archiveRestoreModalState?.isOpen}
					entityName="item"
					entityType="itemsList"
					mode={this.state.archiveRestoreModalState?.data?.isEnabled ? "archive" : "restore"}
					dataObject={this.state.archiveRestoreModalState?.data}
					close={this.closeArchiveRestoreModal}
				/>
			</div>
		);
	}
}
