import React, { useState, useEffect } from "react";

// components
import { Button } from "../_commons/Button";
import { ButtonIcon } from "../_commons/ButtonIcon";
import { Loading } from "../_commons/Loading";
import MenuStatus from "./MenuStatus";

// actions
import { fetchDspAggregatedStatus } from "../../actions/dspIntegration";

// constants
import { PLATFORM_MAP } from "../Hub/Integrations";
export const STATUS_NAME_MAP = {
	DEFAULT: "Mapping Your Stores",
	MENU_REQUESTED: "Menu Requested",
	MENU_QUALIFIED: "Menu Qualified",
	INTEGRATION_ACTIVATED: "Integration Activated"
};
const STATUS_ICON_MAP = {
	done: {
		icon: "success-circle",
		color: "#0DA125"
	},
	wait: {
		icon: "wait-time",
		color: "#AAAAAA"
	},
	open: {
		icon: "loading",
		color: "#2f58f2"
	},
	warning: {
		icon: "error-triangle",
		color: "#D64949"
	}
};

const PublishAndActivate = ({
	activationSuccess,
	cardProps,
	dspIntegration,
	fetchDspPlatformLocationsList,
	handleSearchFilter,
	handleStatusFilter,
	handlePagination,
	handlePageSize,
	handleAssociationUpdates,
	updateDspIntegrationState,
	handleCancel
}) => {
	console.log(
		activationSuccess,
		cardProps,
		dspIntegration,
		fetchDspPlatformLocationsList,
		handleSearchFilter,
		handleStatusFilter,
		handlePagination,
		handlePageSize,
		handleAssociationUpdates,
		updateDspIntegrationState,
		handleCancel
	);
	const { loading, platform, aggregatedStatus = [] } = dspIntegration;
	const [isMenuStatusOpen, setMenuStatusOpen] = useState(false);
	const [selectedMenuStatusType, setSelectedMenuStatusType] = useState({});
	const activationStatus =
		!loading &&
		(!aggregatedStatus?.length ||
			aggregatedStatus?.find((type) => type?.status === "INTEGRATION_ACTIVATED")?.iconState === "done")
			? true
			: false;

	useEffect(() => {
		fetchDspAggregatedStatus(platform);
	}, []);

	const handleOpenMenuStatusDetail = (name, status) => {
		// handleStatusFilter("dsp_status", {value: status});
		setSelectedMenuStatusType({ name, status });
		setMenuStatusOpen(true);
	};

	const closeMenuStatusDetail = () => {
		setMenuStatusOpen(false);
		setSelectedMenuStatusType({});
		// handleStatusFilter("dsp_status", {value: "all"});
	};

	if (!activationSuccess) {
		return (
			<div className="dsp-locations">
				<div className="publish-store-activation-status">
					<div className="header">
						<div className="status-icon">
							<img src={cardProps?.header?.icon} alt="" />
						</div>
						<div className="header-text">{cardProps?.header?.text}</div>
						<div className="header-sub-text">{cardProps?.header?.subText}</div>
					</div>
					<div className="footer">
						<Button clickHandler={cardProps?.footer?.primaryHandler}>{cardProps?.footer?.primary}</Button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="dsp-locations">
			<div className="publish-store-activation-status">
				<div className="header">
					<div className="status-icon">
						<ButtonIcon
							icon={activationStatus ? "success-circle" : "wait-time"}
							color={activationStatus ? "#2ECC71" : "#E5B917"}
						/>
					</div>
					<div className="header-text">Activation {activationStatus ? "Successful" : "Initiated"}</div>
					<div className="header-sub-text">
						{activationStatus
							? "You will start receiving online orders on our platform and/or in your POS."
							: "Processing takes a few minutes. Post activation, you will start receiving online orders on our platform and/or in your POS."}
					</div>
				</div>
				{aggregatedStatus?.length > 0 && (
					<div className="activation-status">
						<div className="title">Activation Status</div>
						<div className="status-wrapper">
							{aggregatedStatus?.map((status, i) => (
								<div className="status" key={i}>
									{status?.iconState === "open" ? (
										<Loading circular color={STATUS_ICON_MAP[status?.iconState]?.color} />
									) : (
										<ButtonIcon
											icon={STATUS_ICON_MAP[status?.iconState]?.icon}
											color={STATUS_ICON_MAP[status?.iconState]?.color}
										/>
									)}
									<div className="type">
										<div className="name">{STATUS_NAME_MAP[status?.status]}</div>
										<div
											className={"count" + (status?.status !== "DEFAULT" ? " link" : "")}
											onClick={
												status?.status !== "DEFAULT"
													? () =>
															handleOpenMenuStatusDetail(
																STATUS_NAME_MAP[status?.status],
																status?.status
															)
													: () => {}
											}
										>
											({status?.completed}/{status?.total}) {STATUS_NAME_MAP[status?.status]}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				)}
				<div className="footer">
					<Button clickHandler={() => handleCancel(true)}>Go back to Integrations</Button>
				</div>
			</div>
			<MenuStatus
				isOpen={isMenuStatusOpen}
				close={closeMenuStatusDetail}
				dspIntegration={dspIntegration}
				selectedMenuStatusType={selectedMenuStatusType}
				fetchDspPlatformLocationsList={fetchDspPlatformLocationsList}
				handleSearchFilter={handleSearchFilter}
				handleStatusFilter={handleStatusFilter}
				handlePagination={handlePagination}
				handlePageSize={handlePageSize}
				handleAssociationUpdates={handleAssociationUpdates}
				updateDspIntegrationState={updateDspIntegrationState}
			/>
		</div>
	);
};
export default PublishAndActivate;
