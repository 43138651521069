import moment from "moment";

export const removeTrailingZeros = (number) => {
	return parseFloat(number).toString();
};

export const getBrandDetail = (brandId, brands) => {
	if (brands && brands.length > 0) {
		return brands.find((brand) => brand?.id == brandId);
	}
	return null;
};

export const getPlatformDetailViaName = (platformName, platforms) => {
	if (platforms && platforms.length > 0) {
		return platforms.find((plt) => plt?.platformName?.toLowerCase() == platformName?.toLowerCase());
	}
	return null;
};

export const getBrandDetailViaName = (brandName, brands) => {
	if (brands && brands.length > 0) {
		return brands.find((brand) => (brand?.name).toLowerCase() == brandName.toLowerCase());
	}
	return null;
};

export const getPlatformNames = (bizPlatforms, lowercase = false) => {
	if (bizPlatforms && bizPlatforms.length > 0) {
		return bizPlatforms
			.map((platform) => {
				if (lowercase) {
					if (platform?.platformName.toLowerCase() == "all platforms") {
						return null;
					}
					return platform?.platformName.toLowerCase();
				} else {
					return platform?.platformName;
				}
			})
			.filter((name) => name !== null);
	}
};

export const getPlatformsDetailsViaId = (platformId, platforms) => {
	if (platforms && platforms.length > 0) {
		return platforms.find((platform) => platform.id == platformId);
	}
	return null;
};

export const dateFormat = (dateString) => {
	if (dateString) {
		const localDate = moment.utc(dateString).local();
		const currentDate = moment();
		if (localDate.isSame(currentDate, "day")) {
			return `Updated: Today ${localDate.format("h:mm A")}`;
		} else {
			return `Updated: ${localDate.format("D MMMM, h:mm A")}`;
		}
	}
};

export const getFutureDate = (days, format = "D MMM YYYY") => {
	return moment().add(days, "days").format(format);
};

export const roundAndFormatNumber = (number, decimalPlaces) => {
	if (isNaN(number)) return "0";

	let roundedNumber = number.toFixed(decimalPlaces);

	// Remove trailing zeros
	let formattedNumber = roundedNumber.replace(/(\.\d*[1-9])0+$|\.0*$/, "$1");
	return formattedNumber;
};

// Function to convert time string in 'H:MMam/pm' format to minutes since midnight

function timeToMinutes(timeStr) {
	// Normalize AM/PM to uppercase
	timeStr = timeStr.toUpperCase();

	// Split time and period
	const [time, period] = timeStr.split(/(AM|PM)/);
	const [hours, minutes] = time.split(":").map(Number);
	let totalMinutes = hours % 12; // Convert 12-hour format to 24-hour format (excluding 12)
	if (period === "PM") totalMinutes += 12;
	totalMinutes = totalMinutes * 60 + minutes;
	return totalMinutes;
}

// Function to parse time range and adjust for crossing midnight
function parseTimeRange(startStr, endStr) {
	const startMinutes = timeToMinutes(startStr);
	const endMinutes = timeToMinutes(endStr);

	let adjustedEndMinutes = endMinutes;
	if (endMinutes < startMinutes) {
		adjustedEndMinutes += 24 * 60; // Adjust for crossing midnight
	}

	return [startMinutes, adjustedEndMinutes];
}

const convertDurationToHoursMinutes = (duration) => {
	const hours = Math.floor(duration / 60);
	const minutes = duration % 60;
	return `${hours}hr ${minutes}min`;
};

// Function to calculate continuous percentages
export const calculateContinuousPercentages = (ranges, indexVal, data) => {
	const segments = [];

	ranges.forEach((r) => {
		const [start, end] = parseTimeRange(r.start, r.end);

		if (segments.length === 0 || segments[segments.length - 1].status !== r.status) {
			segments.push({ start, end, status: r.status });
		} else {
			segments[segments.length - 1].end = end;
		}
	});

	// Calculate total duration

	const totalDuration = segments.reduce((total, seg) => total + (seg.end - seg.start), 0);

	// Calculate percentage for each segment
	const percentages = segments.map((seg, index) => {
		const duration = seg.end - seg.start;
		const percentage = (duration / totalDuration) * 100;
		const durationFormatted = convertDurationToHoursMinutes(duration);

		return {
			value: `${percentage.toFixed(2)}%`,
			status: seg.status,
			duration: `${data[indexVal][index].start}-${data[indexVal][index].end}`,
			totalTime: durationFormatted
		};
	});
	return percentages;
};

export const getUniqueKeys = (data) => {
	const durations = new Set();
	data.forEach((periodData) => {
		periodData.forEach((entry) => {
			if (entry.duration) {
				durations.add(entry.duration);
			}
		});
	});

	return Array.from(durations);
};

const getColorByStatus = (status) => {
	switch (status) {
		case "online":
			return "#D9EFE1";
		case "offline":
			return "#FFCCCC";
		case "data unavailable":
			return "#F5F5F5";
		default:
			return "#FFFFFF";
	}
};

export const transformData = (data, keys) => {
	return data.map((periodData, index) => {
		const transformed = { country: periodData[0].country }; // Use the period as the country
		keys.forEach((key) => {
			const item = periodData.find((item) => item.duration === key);
			transformed["country"] = index;
			if (item) {
				transformed[key] = parseFloat(item.value); // Use numeric value
				transformed[`${key}_color`] = getColorByStatus(item.status); // Add color
				transformed[`${key}_data`] = item;
			} else {
				transformed[key] = 0; // Default value if not found
				transformed[`${key}_color`] = getColorByStatus("data unavailable"); // Default color
			}
		});
		return transformed;
	});
};
