import React, { useMemo } from "react";
import { connect } from "react-redux";
import Bar from "../../../NivoCharts/Bar";
import { metricContainer } from "../../../../containers/Analytics/CompareTableWidgets";
import { printCurrency } from "../../../SiteComp";
import { commifyNumbers } from "../../../../atlas-utils";
import { roundAndFormatNumber } from "../../../../helpers/analytics";
import ExclamatoryIcon from "../../../_icons/ExclamatoryIcon";

const BreakdownInfo = React.memo(
	({
		rowTitle,
		currIndex,
		metric,
		currency = false,
		displayText = "",
		suffix = "",
		login,
		columnDataWidth = 0,
		compareAnalytics,
		space = false,
		roundOff = 0
	}) => {
		const currentData = compareAnalytics.columnData?.[metric].data?.[currIndex];
		const tableData = compareAnalytics.tableData?.[metric]?.[currentData?.valueForDisplay];
		if (!tableData) {
			return null;
		}
		const currencySymbol = login.loggedInbizDetail?.currencySymbol;
		const breakdown = compareAnalytics?.breakdown;
		const data = tableData?.[rowTitle];
		const error = data?.error;
		const loading = data?.loading;
		const totalSum =
			(data?.objects || []).length > 0
				? data?.objects.reduce((acc, sum) => {
						return acc + (sum?.value || 0);
				  }, 0)
				: 0;
		const displayInfo = compareAnalytics?.displayInfo;
		const objectLoading = data?.objectLoading;
		const objectError = data?.objectError;

		const valueDisplay = (
			<div className="mini-title title-dark">
				{displayInfo == rowTitle &&
					!objectLoading &&
					data?.value >= 0 &&
					(metric === "platform" ? !!breakdown?.[rowTitle]?.[metric] : true) && (
						<span className="title-text">
							{displayText ? displayText : metricContainer?.[rowTitle]?.name}
						</span>
					)}
				<span className={`${space ? "space-btw" : ""}`}>
					{data?.value >= 0 ? (
						<>
							{currency && printCurrency(currencySymbol)}
							{<>{commifyNumbers(roundAndFormatNumber(data?.value, roundOff))}</>}
							<span>{suffix.length > 0 && suffix}</span>
						</>
					) : (
						<></>
					)}
				</span>
			</div>
		);

		return (
			<div className="breakdown-container">
				{error || loading || ((objectError || objectLoading) && displayInfo === rowTitle) ? (
					<div className="compare-loading">
						{displayInfo == rowTitle && (objectLoading || objectError) ? (
							<div className="breakdown-loader" style={{ position: "relative" }}>
								<div className={`${objectLoading ? "shimmer" : "error"} W${100}% H(${"20px"}) title`} />
								{Array.from({ length: 5 }).map((_, index) => (
									<div
										key={index}
										className={`${objectLoading ? "shimmer" : "error"} W${100}% H(16px) ${
											index != 4 ? "text" : ""
										}`}
									/>
								))}
								{objectError ? (
									<div className="error-info">
										<div className="exclamatory-div">
											<ExclamatoryIcon fill={"#EAEAEA"} width={72} height={72} />
										</div>
										<div className="text-div">
											<div className="main-text">Could not load this chart!</div>
											<div className="para">
												We are facing some issue while fetching this data for you
											</div>
										</div>
									</div>
								) : (
									<></>
								)}
							</div>
						) : (
							<div className={`${loading ? "shimmer" : "error"} single-bar`} />
						)}
					</div>
				) : (
					<>
						{valueDisplay}
						{displayInfo === rowTitle && data?.value !== 0 && (
							<div className="compare-bar">
								{(data?.objects || []).length > 0 && rowTitle == displayInfo && data?.value > 0 ? (
									<>
										<Bar
											height={(data?.objects).length * 36}
											padding={0.3}
											innerPadding={0}
											marginTop={5}
											marginBottom={100}
											marginLeft={10}
											marginRight={210}
											data={data?.objects}
											animate={false}
											keys={["value"]}
											indexBy={"name"}
											layout="horizantal"
											enableLegends={false}
											label={false}
											enableGridX={false}
											showAxisBottom={false}
											showAxisLeft={false}
											enableGridY={false}
											isInteractive={false}
											customOuterLabel={true}
											renderCustomOuterLabel={(props, i, data) => {
												return (
													<foreignObject
														key={i}
														width={
															(props?.width && data[i].value > 0
																? columnDataWidth - 68
																: columnDataWidth - 60) - props?.width
														}
														height="76px"
														transform={`translate(${
															(props?.width && data[i].value > 0 ? props?.x : 0) +
															(data[i].value == 0 ? 0 : props?.width + 8)
														}, ${props?.y + (props?.height - 18) / 2})`}
													>
														{rowTitle == "orderCompletionTime" ? (
															<OrderCompletionTime
																data={data}
																i={i}
																suffix={suffix}
																space={space}
																roundOff={roundOff}
															/>
														) : (
															<ForeignObjectJsx
																currency={currency}
																data={data}
																i={i}
																currencySymbol={currencySymbol}
															/>
														)}
													</foreignObject>
												);
											}}
										/>
									</>
								) : (
									<></>
								)}
							</div>
						)}
						{data?.value == 0 &&
							displayInfo === rowTitle &&
							(metric === "platform" ? !!breakdown?.[rowTitle]?.[metric] : true) && (
								<>
									<div className="not-enough-state">
										<img src="/assets/analytics/compare/barChart.svg" className="bar-svg" />
										<div className="text-div">
											<div className="main-text">Not enough data</div>
											<div className="para">
												Try selecting another date range or check back later once more data is
												collected.
											</div>
										</div>
									</div>
								</>
							)}
					</>
				)}
			</div>
		);
	}
);

export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	configItems: store.configItems,
	isMultibrandEnabled: store.login?.loggedInbizDetail?.isMultibrandEnabled,
	login: store.login,
	compareAnalytics: store.compareAnalytics
}))(BreakdownInfo);

const OrderCompletionTime = ({ data, i, suffix, space, roundOff }) => {
	return (
		<div className="graph-breakdown">
			<div className="value">
				<div className={`${space ? "space-btw" : ""}`}>
					{commifyNumbers(roundAndFormatNumber(data[i].value, roundOff))}
					<span className="min">{suffix}</span>
				</div>
			</div>
			<div className="index">
				<span className="indexValue">{data[i].name}</span>
			</div>
		</div>
	);
};

const ForeignObjectJsx = ({ data, i, currencySymbol, currency }) => {
	return (
		<>
			<div className="graph-breakdown">
				<div className="value">
					{data[i].value == 0 ? (
						<> {currency && printCurrency(currencySymbol)}0</>
					) : (
						<>
							{currency && printCurrency(currencySymbol)}
							{commifyNumbers(data[i].value)}
						</>
					)}
				</div>
				<div className="index">
					<span className="indexValue">{data[i].name}</span>
					<span className="percent">{commifyNumbers(data[i].percentage)}%</span>
				</div>
			</div>
		</>
	);
};
