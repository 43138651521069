import React, { useState, useEffect, useMemo } from "react";

// components
import Breadcrumbs from "../_commons/Breadcrumbs";
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import Placeholder from "../_commons/Placeholder";
import Popover from "../_commons/Popover";
import { ButtonIcon } from "../_commons/ButtonIcon";

// third party
import { connect } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom";

// store
import { store } from "../../store/configureStore";

// utils
import { trackEvent, capitaliseText } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";
import { getAppliedFilter } from "./ExportReportHistory";
import { fetchScheduleReportHistory } from "../../actions/reports";

// constants
import { REPORTS_GROUP_MAP } from "./Overview";
const columns = [
	{
		name: "Date",
		field: "created-date",
		render: (record, i, rest) => (
			<div className="table-cell created-date" key={i}>
				<div>{moment(record.createdAt)?.format("DD MMM, YYYY - hh:mm A") || "--"}</div>
				<div className="user" title={record?.requestedUserEmail || record?.requestedUser?.fullName || null}>
					{record?.requestedUserEmail
						? `By: ${record?.requestedUserEmail}`
						: record?.requestedUser?.fullName
						? `By: ${record?.requestedUser?.fullName}`
						: ""}
				</div>
			</div>
		)
	},
	{
		name: "Scheduled For",
		field: "scheduled-for",
		render: (record, i, rest) => (
			<div className="table-cell scheduled-for" key={i}>
				{record.scheduleType || ""}
			</div>
		)
	},
	{
		name: "Data Period",
		field: "data-period",
		render: (record, i, rest) => (
			<div className="table-cell data-period" key={i}>
				{record.showDataFor || ""}
			</div>
		)
	},
	{
		name: "Filters Applied",
		field: "filters-applied",
		render: (record, i, rest) => {
			const showMore =
				record.filtersApplied?.length > 2 ||
				record.filtersApplied?.[0]?.values?.length > 4 ||
				record.filtersApplied?.[1]?.values?.length > 4 ||
				false;
			return (
				<div key={i} className="table-cell filters-applied">
					{record?.filtersApplied?.slice(0, 2)?.map((filter, j) => (
						<div title={getAppliedFilter(filter, true)} className="filter" key={j}>
							<span>{filter.title}:</span>
							{getAppliedFilter(filter, true)}
						</div>
					))}
					{showMore && (
						<Popover
							data={record}
							showOnHover={false}
							showOnClick={true}
							renderPopover={rest.handleFiltersPopover}
							position="down-right"
						>
							<div className="more">+ more</div>
						</Popover>
					)}
				</div>
			);
		}
	},
	{
		name: "Recipient",
		field: "recipient",
		render: (record, i, rest) => (
			<div className="table-cell recipient" key={i}>
				{record.requestedForEmails?.length > 0 &&
					record.requestedForEmails?.slice(0, 2)?.map((email, j) => (
						<div key={j} className="email" title={email}>
							{email}
						</div>
					))}
				{record.requestedForEmails?.length > 2 && (
					<Popover
						data={record}
						showOnHover={false}
						showOnClick={true}
						renderPopover={rest.handleEmailsPopover}
						position="down-right"
					>
						<div className="more">+ more</div>
					</Popover>
				)}
			</div>
		)
	},
	{
		name: "Size",
		field: "size",
		render: (record, i, rest) => (
			<div className="table-cell size" key={i}>
				{record.reportSize
					? record.reportSize >= 1000
						? `${parseFloat((record.reportSize / 1000).toFixed(1))} MB`
						: `${parseFloat(record.reportSize.toFixed(1))} KB`
					: "--"}
			</div>
		)
	},
	{
		name: "Access Report",
		field: "access-report",
		render: (record, i, rest) => (
			<div className="table-cell access-report" key={i}>
				<div
					className="download"
					title={`Download ${record?.exportFormat?.toUpperCase()}`}
					onClick={() => rest.handleDownloadReport(record)}
				>
					<div>Download</div>
					<ButtonIcon icon="download" color="#AAAAAA" />
				</div>
			</div>
		)
	}
];
const PLACEHOLDER = {
	placeholderText: "You haven’t received any reports yet",
	placeholderImageUrl: "/assets/empty_states/graphics-empty-reports.svg",
	placeholderSubtext: "All reports dispatched as part of this schedule will be listed down here",
	size: "medium"
};

const ScheduleReportHistory = ({ match, scheduleReportHistory }) => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const [isLoading, setIsLoading] = useState(false);
	const { loading, limit, offset, currPage, prevId, nextId, data } = scheduleReportHistory;

	useEffect(() => {
		fetchScheduleReportHistory(match.params.id);
	}, [match.params.id, prevId, nextId, offset]);

	useEffect(() => {
		// track event
		trackEvent("schedule_report_log_view", {
			report_name: capitaliseText(query.get("name")?.split("-")?.join(" ")),
			report_category: REPORTS_GROUP_MAP[query.get("group")]?.name || ""
		});
	}, []);

	const updateScheduleReportHistoryState = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_SCHEDULE_REPORT_HISTORY,
			payload
		});
	};

	const handleResetScheduleReportHistory = () => {
		store.dispatch({
			type: ActionTypes.RESET_SCHEDULE_REPORT_HISTORY
		});
	};

	const handlePagination = async (action) => {
		// set new offset based on action
		let newCurrPage = undefined;
		let newPrevId = "";
		let newNextId = "";
		switch (action) {
			case "first":
				newCurrPage = 1;
				newPrevId = "";
				newNextId = "";
				break;
			case "prev":
				newCurrPage = currPage - 1;
				newPrevId = data.objects?.[0]?.reportId;
				newNextId = "";
				break;
			case "next":
				newCurrPage = currPage + 1;
				newPrevId = "";
				newNextId = data.objects?.slice(-1)?.[0]?.reportId;
				break;
			default:
				break;
		}
		updateScheduleReportHistoryState({
			offset: (newCurrPage - 1) * limit,
			currPage: newCurrPage,
			prevId: newPrevId,
			nextId: newNextId
		});
	};

	const handlePageSize = async (field, size) => {
		// set new limit
		if (size && size?.value !== limit) {
			updateScheduleReportHistoryState({
				[field]: size.value,
				offset: 0,
				currPage: 1,
				prevId: "",
				nextId: ""
			});
		}
	};

	const handleDownloadReport = (record) => {
		// download report
		if (record?.downloadLink) {
			setIsLoading(true);
			const link = document.createElement("a");
			link.href = record?.downloadLink;
			link.click();
		}
		setTimeout(() => {
			setIsLoading(false);
		}, 3000);
	};

	const handleFiltersPopover = (record) => {
		return (
			<div className="filters-applied-popover">
				<div className="header">{record?.report?.name}</div>
				<div className="filters-list">
					{record.filtersApplied
						?.filter((filter) => filter.id !== "time_period")
						?.map((filter, i) => (
							<div className="filter-row" key={i}>
								<div className="name">{filter.title}</div>
								<div className="value">{getAppliedFilter(filter, false)}</div>
							</div>
						))}
				</div>
			</div>
		);
	};

	const handleEmailsPopover = (record) => {
		return (
			<div className="emails-popover">
				<div className="header">{record?.report?.name}</div>
				<div className="emails-list">
					<div className="email-row">
						<div className="name">Format</div>
						<div className="value">{(record.exportFormat || "--")?.toUpperCase()}</div>
					</div>
					<div className="email-row">
						<div className="name">Shared to</div>
						<div className="value">
							{getAppliedFilter({ type: "multiple", values: record.requestedForEmails }, true)}
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="schedule-report-history section-container-common">
			<Breadcrumbs
				connectedLinks={[
					{
						title: "Schedules",
						to: `/reports/list/schedules`,
						onClick: handleResetScheduleReportHistory
					}
				]}
			/>
			<div className="settings-header no-border">
				<div>
					<div className="header-text">{query.get("name")?.split("-")?.join(" ") || "Report"}</div>
					<div className="subheader-text">Schedule history of last 30 reports</div>
				</div>
			</div>
			<CustomTable
				bordered
				columns={columns}
				loading={loading || isLoading}
				data={data?.objects || []}
				handleFiltersPopover={handleFiltersPopover}
				handleEmailsPopover={handleEmailsPopover}
				handleDownloadReport={handleDownloadReport}
				classes="schedule-report-history-table-container"
				customPlaceholder={<Placeholder {...PLACEHOLDER} />}
			/>
			<Paginator
				limit={limit}
				offset={offset}
				currPage={currPage}
				count={100} // random number above 10 to show/render paginator component, using pagination similar to Audit Events (Activity History)
				goToPage={handlePagination}
				setPageSize={handlePageSize}
				showPageSize={true}
				showTotalPages={false}
				restrictPagination={true}
				loading={loading}
				hasNext={data.hasMore}
				hasPrevious={data.hasPrevious}
			/>
		</div>
	);
};
export default connect((store) => ({
	scheduleReportHistory: store.scheduleReportHistory
}))(ScheduleReportHistory);
