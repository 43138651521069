import React from "react";

export const Loading = ({
	inlineStyle = undefined,
	circular = false,
	color = "#2f58f2",
	svgStyle = undefined,
	circleClass = ""
}) => {
	if (circular) {
		return (
			<div className={`loader-circular ${circleClass}`} style={inlineStyle}>
				<svg viewBox="22 22 44 44" style={svgStyle}>
					<circle cx="44" cy="44" r="19" fill="none" stroke={color} stroke-width="5"></circle>
				</svg>
			</div>
		);
	}
	return (
		<div className="loader-backdrop" style={inlineStyle}>
			<div className="loading-indicator-container">
				<div className="loading-indicator loading-indicator-in" />
				<div className="loading-indicator loading-indicator-out" />
			</div>
		</div>
	);
};
