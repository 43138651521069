import React, { useEffect, useRef, useState } from "react";
import SaveComparison from "./SaveComparison";
import SectionHeader from "../Common/SectionHeader";
import { SelectFilterCustom } from "../../_commons/SelectFilterCustom";
import Filters from "../../Analytics/Common/Filters";
import { getEncodedAnalyticsFilters } from "../../../actions/analytics";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { store } from "../../../store/configureStore";
import { ActionTypes } from "../../../actions/_types";
import { connect } from "react-redux";
import ChevronIcon from "../../_icons/ChevronIcon";

const Header = ({
	userId,
	saveComparisonText,
	metrics,
	compareAnalytics,
	status,
	savedFor,
	analyticsFiltersState,
	initialfilters,
	initialColumnValues
}) => {
	const saveDropdownOptions = [
		{ name: "Save as New", id: "Save as New" },
		{ name: "Update Existing", id: "Update Existing" }
	];
	const count = compareAnalytics.columnData?.[metrics]?.count;
	const [saveType, setSaveType] = useState("Save as New");
	const handleSaveBtn = (value) => {
		if (!filterDisabled && status == "saved") {
			if (value.id == "Save as New") {
				if (saveType != "Save as New") {
					setSaveType(value.id);
				}
			} else {
				setSaveType(value.id);
			}
			setFormState(true);
		}
	};
	const [formState, setFormState] = useState(false);

	const history = useHistory();
	const handleClick = () => {
		history.push(`/analytics/compare?filters=${getEncodedAnalyticsFilters()}`);
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_INITIAL_COLUMN_DATA
		});
	};

	const handleClearFilters = () => {
		if (resetRef.current) {
			resetRef.current.clear();
		}
	};

	const resetRef = useRef(null);
	const location = useLocation();
	useEffect(() => {
		if (!location?.state?.handleClearFilters) {
			handleClearFilters();
			store.dispatch({
				type: ActionTypes.ANALYTICS_FILTERS_STATE_RESET
			});
		}
	}, []);

	const [filterDisabled, setFilterDisabled] = useState(status == "draft" ? false : !userId);
	let ans = (compareAnalytics?.columnData?.[metrics]?.data || []).reduce((acc, index) => {
		if (index?.value) {
			acc.push(index.value);
		}
		return acc;
	}, []);
	const filtersApplied = {
		appliedDateFilter: analyticsFiltersState.appliedDateFilter,
		appliedFilters: analyticsFiltersState.appliedFilters
	};

	function areFiltersEqual() {
		const appliedDateFilter = filtersApplied.appliedDateFilter.current.dateFilter;
		const initialDateFilter = initialfilters?.appliedDateFilter?.current?.dateFilter;
		const appliedBrandId = JSON.stringify(filtersApplied.appliedFilters.brand_id);
		const initialBrandId = JSON.stringify(initialfilters?.appliedFilters?.brand_id);
		const appliedLocationId = JSON.stringify(filtersApplied.appliedFilters.location_id);
		const initialLocationId = JSON.stringify(initialfilters?.appliedFilters?.location_id);
		const appliedPlatformNames = JSON.stringify(filtersApplied.appliedFilters.platform_names);
		const initialPlatformNames = JSON.stringify(initialfilters?.appliedFilters?.platform_names);
		let initialColumnData = initialColumnValues ? JSON.parse(initialColumnValues) : [];
		let checkColumnData = ans.every((element, index) => {
			return String(element) == String(initialColumnData[index]);
		});
		return (
			appliedDateFilter === initialDateFilter &&
			appliedBrandId === initialBrandId &&
			appliedLocationId === initialLocationId &&
			appliedPlatformNames === initialPlatformNames &&
			checkColumnData &&
			initialColumnData.length == ans.length
		);
	}

	useEffect(() => {
		if (status == "draft") {
			setFilterDisabled(false);
		}
	}, [userId, status]);
	useEffect(() => {
		if (areFiltersEqual() !== filterDisabled && status === "saved") {
			setFilterDisabled(areFiltersEqual());
		}
	}, [
		filtersApplied,
		initialfilters,
		JSON.stringify(compareAnalytics?.columnData?.[metrics]?.data),
		JSON.stringify(filtersApplied)
	]);

	const openSaveComparisonDrawer = () => {
		if (count > 1) {
			if (userId && status === "saved") {
				return;
			}
			if (filterDisabled && status === "saved") {
				return;
			}
			setFormState(true);
		}
	};

	return (
		<>
			<SaveComparison
				formState={formState}
				setFormState={setFormState}
				name={saveComparisonText}
				userId={userId}
				saveType={saveType}
				status={status}
				savedFor={savedFor}
				initialfilters={initialfilters}
				initialColumnValues={initialColumnValues}
			/>
			<div className="analytics-header" id="firstDiv">
				<div>
					<div className="specific-header-compare">
						<div
							className="small-heading compare-top-head"
							style={{ cursor: "pointer" }}
							onClick={handleClick}
						>
							<span>Compare Performance</span>
							<ChevronIcon
								styles={{ rotate: "-90deg", width: "16px", height: "16px" }}
								variations={{ fill: "#AAAAAA" }}
							/>
						</div>
						<SectionHeader
							title={`Compare ${metrics}`}
							headerRight={
								<div
									className={`save-btn ${
										count > 1 && (status == "draft" ? !filterDisabled : !filterDisabled)
											? "clickable"
											: "btn-disabled"
									}  ${
										count > 1 && userId && status == "saved" && !filterDisabled
											? "view-btn "
											: "remove-btn"
									}`}
									onClick={() => openSaveComparisonDrawer()}
								>
									<SelectFilterCustom
										options={count > 1 && !filterDisabled ? saveDropdownOptions : []}
										field="save-type"
										currValue={""}
										setFilter={(f, value) => handleSaveBtn(value)}
										labelKey={"name"}
										valueKey={"id"}
										customLabel={false}
										customOptions={false}
										placeholder="Save Comparison"
										isSearchable={false}
										renderOptions={(value) => {
											return <div> {value.name} </div>;
										}}
										renderLabel={(value) => {
											return <div> {value.name} </div>;
										}}
									/>
								</div>
							}
						/>
					</div>

					<Filters
						showBrands={metrics !== "brand"}
						showLocations={metrics !== "location"}
						showPlatforms={metrics !== "platform"}
						showDateCompare={true}
						includeAllTime={true}
						module="Compare"
						optionChange={metrics}
						resetRef={resetRef}
					/>
				</div>
			</div>
		</>
	);
};

export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	compareAnalytics: store.compareAnalytics
}))(Header);
