import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

// components
import App from "./App";
import ThemeProvider from "@urbanpiper-engineering/horizon/dist/provider";

// third party
import { Provider } from "react-redux";
import { init } from "@sentry/browser";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

// store
import { store } from "./store/configureStore";

// utils
// import { getClientID } from "./atlas-utils";
import { initUtility } from "./atlas-utils/init";

// styles
import "@urbanpiper-engineering/horizon/dist/main.css";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
// constants
// const LD_CLIENT_SIDE_ID = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;

// initiate util methods
initUtility();

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
	person_profiles: "identified_only",
	autocapture: false,
	capture_pageview: false,
	capture_pageleave: false,
	enable_recording_console_log: true,
	advanced_disable_feature_flags_on_first_load: true
});

// Assign posthog to window object
window.posthog = posthog;
// render the app
const renderApp = async () => {
	// const LDProvider = await asyncWithLDProvider({
	// 	clientSideID: LD_CLIENT_SIDE_ID,
	// 	context: {
	// 		kind: 'multi',
	// 		app: {
	// 			key: 'atlas',
	// 			clientId: getClientID()
	// 		},
	// 	}
	// });
	ReactDOM.render(
		<React.StrictMode>
			<PostHogProvider client={posthog}>
				<ThemeProvider>
					<DndProvider backend={HTML5Backend}>
						<Provider store={store}>
							<App />
						</Provider>
					</DndProvider>
				</ThemeProvider>
			</PostHogProvider>
			{/* <LDProvider>
			</LDProvider> */}
		</React.StrictMode>,
		document.getElementById("root")
	);
};
renderApp();

// setup sentry if enabled
const TRACKING_ENABLED = process.env.REACT_APP_TRACKING_ENABLED;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
if (TRACKING_ENABLED === "yes") {
	init({
		dsn: SENTRY_DSN
	});
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
