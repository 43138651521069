import React, { useState, useEffect } from "react";

// third party
import { connect } from "react-redux";
import history from "../../history";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchDspPlatformsList } from "../../actions/dspIntegration";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { DSP_INTEGRATION_INITIAL_STATE } from "../../reducers/dspIntegration";
export const PLATFORM_MAP = {
	ubereats: "Uber Eats",
	doordash: "DoorDash"
};

const Integrations = ({ dspPlatformsList, dspIntegration, isMenuOverCatalogueEnabled = false }) => {
	const { isLoading, objects, count } = dspPlatformsList;
	const [hasError, setHasError] = useState(false);
	const redirectToHub = () => {
		history.push("/hub");
	};

	useEffect(() => {
		if (isMenuOverCatalogueEnabled) {
			fetchDspPlatformsList();
		} else {
			redirectToHub();
		}
	}, []);

	const handleDSPIntegration = (dsp, isDSPIntegrated = false) => {
		// update state
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				...DSP_INTEGRATION_INITIAL_STATE,
				selectedBrand: dspIntegration?.platform === dsp ? dspIntegration?.selectedBrand : null,
				isDSPIntegrated,
				isValidDSP: true,
				platform: dsp
			}
		});
		// redirect
		history.push(`/hub/integrations/${dsp}`);
	};

	useEffect(() => {
		const errorFound = objects.some((obj) => obj.syncStatus === "error");
		setHasError(errorFound);
	}, [objects]);

	if (isLoading && objects?.length === 0) {
		return (
			<div>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	if (!isLoading && objects?.length === 0) {
		return (
			<div className="integrations-container">
				<div className="header-text">Integrate an Online Ordering Platform</div>
				<div className="no-items-placeholder">No Platforms found</div>
			</div>
		);
	}

	return (
		<div className="integrations-container">
			<div className="header-text">Integrate an Online Ordering Platform</div>
			{hasError ? (
				<div
					className="status-alert-container"
					style={{
						borderLeft: `6px solid #D64949`,
						backgroundColor: "#FFF5F5"
					}}
				>
					<div className="error-icon">
						<img src="/assets/icons/error-red-icon.svg" />
					</div>

					<div className="error-text-link">
						<div className="main-error-header">Requests failed for 1 Online Ordering Platform</div>
						<div className="main-error-desc">
							Review failed requests for DSPs to take locations live on UrbanPiper
						</div>
					</div>
				</div>
			) : null}

			<div className={"dsps" + (isLoading ? " disabled" : "")}>
				{objects.map((plf, i) => (
					<div
						key={i}
						className="plf-card-container"
						onClick={() => handleDSPIntegration(plf?.platform?.toLowerCase(), !plf?.isExpired)}
					>
						<div className="icon" title={PLATFORM_MAP[plf.platform] || plf.platform}>
							<img
								src={
									CATALOGUE_PLATFORMS_LOGO[plf?.platform?.toLowerCase()] ||
									"/assets/icons/icons8-globe-40.png"
								}
								alt=""
							/>
						</div>
						<div className="platform">
							<div className="link">
								<div className="name">{PLATFORM_MAP[plf.platform] || plf.platform}</div>
								{!plf?.isExpired && (
									<div className="info">
										{plf?.activeStoresCount > 0 ? (
											<div className="live-stores">
												<img src="/assets/icons/blue-dot.svg" alt="" />
												<div className="text">
													{plf?.activeStoresCount} /{" "}
													{plf?.activeStoresCount +
														plf?.mappedStoresCount +
														plf?.unmappedStoresCount}
													{plf?.activeStoresCount +
														plf?.mappedStoresCount +
														plf?.unmappedStoresCount ===
													1
														? " Store"
														: " Stores"}{" "}
													Active
												</div>
											</div>
										) : null}
										{plf?.syncStatus == "inprogess" ? (
											<div className="store-status store-status-inprogress">
												<div className="text">Mapping in progress</div>
											</div>
										) : plf?.syncStatus == "failed" ? (
											<div className="store-status store-status-error">
												<div className="text">Partially Failed</div>
											</div>
										) : (
											<div className="store-status store-status-success">
												<div className="text">Mapping Complete</div>
											</div>
										)}
										<img src="/assets/icons/icon-right-arrow.svg" alt="" />
									</div>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	dspPlatformsList: store.dspPlatformsList,
	dspIntegration: store.dspIntegration,
	isMenuOverCatalogueEnabled: store.login.loggedInbizDetail?.isMenuOverCatalogueEnabled || false
});
export default connect(mapStateToProps)(Integrations);
