import React, { useState, useCallback, useRef } from "react";

// components
import { CustomTable } from "../_commons/CustomTable";
import { SelectFilter } from "../_commons/SelectFilter";
import { Paginator } from "../_commons/Paginator";
import CreateIcon from "../_commons/CreateIcon";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";

// utils
import { capitaliseText, adjustNestedContainer } from "../../atlas-utils";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchMenusDebounced } from "../../actions/actions";

// constants
import { NESTED_ENTITY_TYPES } from "../../client-config";
import { PLATFORM_MAP } from "../Hub/Integrations";
const columns = [
	{
		name: <div className="name">Store Name</div>,
		field: "up-stores",
		render: (record, i, rest) => (
			<div className="table-cell up-stores" key={i}>
				<div className="name">
					{rest?.associationUpdates?.[record?.id]?.location?.name || record?.location?.name}
				</div>
				<div className="description">
					{record.id}
					{PLATFORM_MAP[record?.platformName] || capitaliseText(record?.platformName)}: {record?.locationName}
				</div>
			</div>
		)
	},
	{
		name: <div className="name">Menu</div>,
		field: "menu",
		render: (record, i, rest) => {
			const currValue = rest?.associationUpdates?.[record?.id]?.associatedMenu
				? rest?.associationUpdates?.[record?.id]?.associatedMenu
				: record?.associatedMenu || null;
			return (
				<div className="table-cell menu with-actions" key={i}>
					<SelectFilter
						options={rest?.menus?.items || []}
						isLoading={rest?.menus?.isLoading}
						field="associatedMenu"
						currValue={currValue}
						setFilter={(field, value) => rest.handleAssociationUpdates(record, field, value)}
						isAsync={true}
						isSearchable={true}
						isClearable={false}
						renderCustomMenuListBottom={rest.handleMenuList(record)}
						handleSearch={(search) => fetchMenusDebounced(search, rest?.selectedBrand?.id)}
						labelKey="name"
						valueKey="menuId"
						placeholder="Associate Menu"
					/>
				</div>
			);
		}
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const AssociateMenu = ({
	dsp,
	stores,
	dspIntegration = {},
	handlePagination,
	handlePageSize,
	handleAssociationUpdates,
	updateDspIntegrationState,
	isMultibrandEnabled = false,
	menus
}) => {
	const { loading, limit, offset, data, associationUpdates, selectedBrand } = dspIntegration;
	const [contextMenuId, setContextMenuId] = useState(undefined);
	const [contextMenuData, setContextMenuData] = useState({});
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isMenuCreateOpen, setMenuCreateOpen] = useState(false);
	const nestedRef = useRef();

	const handleNestedEntity = useCallback((toOpen = false, type, record) => {
		if (!toOpen) {
			setMenuCreateOpen(false);
			setContextMenuData({});
			setTimeout(() => setNestedEntity(NESTED_ENTITY_INITIAL_STATE), 100);
		} else {
			setNestedEntity({
				show: true,
				type
			});
			setContextMenuData(record);
			// update selected brand in menu create drawer
			if (isMultibrandEnabled) {
				store.dispatch({
					type: ActionTypes.UPDATE_MENUS_LIST_DATA,
					payload: {
						key: "selectedBrand",
						value: selectedBrand || null
					}
				});
			}
			setTimeout(() => setMenuCreateOpen(true), 60);
		}
		adjustNestedContainer(toOpen);
	}, []);

	const openContextMenu = (record) => {
		if (!contextMenuId || contextMenuId !== record?.id) {
			setContextMenuId(record?.id);
			return;
		}
		setContextMenuId(undefined);
	};

	const closeContextMenu = () => {
		setContextMenuId(undefined);
	};

	const handleCopyToAll = (menu = null) => {
		const newAssociationUpdates = { ...associationUpdates };
		data.objects.forEach((loc) => {
			newAssociationUpdates[loc?.id] = {
				...(newAssociationUpdates[loc?.id] ?? {}),
				id: loc?.id,
				externalId: loc?.externalId,
				locationName: loc?.locationName,
				platformName: loc?.platformName,
				location: loc?.location || null,
				isStoreMapped: loc?.isStoreMapped,
				associatedMenu: menu || null
			};
		});
		updateDspIntegrationState({
			associationUpdates: newAssociationUpdates
		});
		closeContextMenu();
	};

	const handleCreateMenu = (record) => {
		// open create menu drawer
		handleNestedEntity(true, NESTED_ENTITY_TYPES[19], record);
		// once menu is created, user will be redirected to menu detail to add sections and items to the menu
		// once menu is saved, handleMenuAction in MenuEdit container will update
		// the associatedMenu in the location entry with the newly created menu
	};

	const handleMenuList = (record) => {
		return (
			<div className="create-menu" onClick={() => handleCreateMenu(record)}>
				<CreateIcon color="#2f58f2" />
				<span>Create a Menu</span>
			</div>
		);
	};

	const renderMenuItems = (record, rest) => {
		return (
			<div
				className="action-item"
				onClick={() =>
					handleCopyToAll(rest?.associationUpdates?.[record.id]?.associatedMenu || record?.associatedMenu)
				}
			>
				Copy to all locations
			</div>
		);
	};

	return (
		<div className="form-content">
			<CustomTable
				bordered={true}
				loading={loading}
				data={data?.objects || []}
				columns={columns}
				classes="locations-table-container"
				content="locations"
				menus={menus}
				dsp={dsp}
				associationUpdates={associationUpdates}
				handleAssociationUpdates={handleAssociationUpdates}
				showContextMenu={true}
				renderMenuItems={renderMenuItems}
				openContextMenu={openContextMenu}
				closeContextMenu={closeContextMenu}
				contextMenuId={contextMenuId}
				handleMenuList={handleMenuList}
				selectedBrand={selectedBrand}
			/>
			<Paginator
				limit={limit}
				offset={offset}
				count={data?.count || 0}
				goToPage={handlePagination}
				setPageSize={handlePageSize}
				showPageSize={true}
			/>
			<NestedEntityContainer
				isOpen={isMenuCreateOpen}
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				menuEditPath={`/hub/integrations/${dsp}/edit/${contextMenuData?.id}/menu/edit/`}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default AssociateMenu;
