import React, { useState, useEffect, useMemo } from "react";

// components
import Breadcrumbs from "../components/_commons/Breadcrumbs";
import MultiActionsButton from "../components/_commons/MultiActionsButton";
import StatusInfo from "../components/_commons/StatusInfo";
import { CustomTable } from "../components/_commons/CustomTable";
import Placeholder from "../components/_commons/Placeholder";
import { Button } from "../components/_commons/Button";
import ReportDownload from "./ReportDownload";
import ShareOnEmail from "../components/Reports/ShareOnEmail";

// third party
import { connect } from "react-redux";
import history from "../history";
import { useLocation } from "react-router-dom";

// utils
import { trackEvent, capitaliseText } from "../atlas-utils";

// store
import { store } from "../store/configureStore";

// actions
import { ActionTypes } from "../actions/_types";
import {
	fetchReportDetail,
	fetchReportPreview,
	generateReportV2,
	fetchPendingReportsTaskList
} from "../actions/reports";

// constants
import { REPORTS_GROUP_MAP } from "../components/Reports/Overview";
const PLACEHOLDER = {
	placeholderText: "No Data Available",
	placeholderImageUrl: "/assets/empty_states/graphics-empty-reports.svg",
	placeholderSubtext:
		"Data is not available with the chosen filters. Please make adjustments to the filters in order to generate a new report.",
	size: "medium"
};

const ReportPreview = ({ match, reportDetail, reportPreview }) => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const [isReportDownloadOpen, setReportDownloadOpen] = useState(false);
	const [showEmailExport, setShowEmailExport] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const {
		loading,
		previewData: { tabularData }
	} = reportPreview;
	const {
		data,
		currentFilters,
		appliedFilters,
		currentDateFilter,
		appliedDateFilter,
		validations,
		optionUpdates,
		isCheckedAll,
		emails
	} = reportDetail;

	const fetchReportDetailAndPreviewData = async () => {
		await fetchReportDetail(`codex##${match.params.id}`);
		await fetchReportPreview();
	};

	useEffect(() => {
		if (!data?.id && !data?.name) {
			fetchReportDetailAndPreviewData();
		}
	}, [data]);

	useEffect(() => {
		// track event
		trackEvent("report_preview", {
			report_name: capitaliseText(query.get("name")?.split("-")?.join(" ")),
			report_category: REPORTS_GROUP_MAP[query.get("group")]?.name || ""
		});
	}, []);

	const handleResetReportDetailAndPreview = () => {
		store.dispatch({
			type: ActionTypes.RESET_REPORT_DETAIL
		});
		store.dispatch({
			type: ActionTypes.RESET_REPORT_PREVIEW
		});
	};

	const handleClose = () => {
		handleResetReportDetailAndPreview();
		setTimeout(() => {
			history.push("/reports/list/overview");
		}, 100);
	};

	const trackReportDownloadEvent = (exportType) => {
		trackEvent("report_download", {
			report_category: REPORTS_GROUP_MAP[data?.group]?.name || "",
			report_name: data?.name || "",
			report_format: exportType?.id?.toUpperCase() || "",
			report_emails: emails?.length || 0,
			download_src: "preview"
		});
	};

	const handleExportReport = async (exportType) => {
		// show email input drawer for report export
		if (exportType.id === "email" && !showEmailExport) {
			// open email export drawer
			setTimeout(() => setShowEmailExport(true), 200);
			return;
		}

		// export report
		setIsLoading(true);
		const status = await generateReportV2(exportType);
		if (status?.success) {
			// track event
			trackReportDownloadEvent(exportType);

			// get pending export report tasks to show in ongoing tasks component
			setTimeout(() => {
				fetchPendingReportsTaskList();
			}, 500);

			// close report preview and redirect to reports list
			// handleClose();
		}
		setIsLoading(false);
	};

	const handleCloseEmailExport = (emailExportSuccess = false, exportType) => {
		// close email export drawer
		setShowEmailExport(false);
		if (emailExportSuccess) {
			// track event
			trackReportDownloadEvent(exportType);

			// close report preview and redirect to reports list
			// handleClose();
		}
	};

	return (
		<div className="report-preview section-container-common">
			<Breadcrumbs
				connectedLinks={[
					{
						title: "Reports",
						to: `/reports/list/overview`,
						onClick: handleResetReportDetailAndPreview
					}
				]}
			/>
			<div className="settings-header no-border">
				<div>
					<div className="header-text">{data?.name || query.get("name")?.split("-")?.join(" ")}</div>
				</div>
				<MultiActionsButton
					title="Export Report"
					actions={
						data?.emailAvailable
							? [
									{
										id: "email",
										title: "Share on Email",
										clickHandler: () => handleExportReport({ id: "email", name: "Share on Email" })
									},
									...(data?.exports ?? [])?.map((type) => ({
										id: type.id,
										title: type.name,
										clickHandler: () => handleExportReport(type)
									}))
								]
							: (data?.exports ?? [])?.map((type) => ({
									id: type.id,
									title: type.name,
									clickHandler: () => handleExportReport(type)
								}))
					}
					isDisabled={loading || isLoading || !data?.exports?.length || !tabularData?.rows?.length}
				/>
			</div>
			{tabularData?.rows?.length === 20 && (
				<StatusInfo
					icon="info"
					status="info"
					color="#3b5feb"
					header="You can view up to 20 rows"
					description="Previews are limited to the first 20 rows, please export it view the full report."
				/>
			)}
			<div className="filters-wrapper">
				<Button
					classes={isLoading ? "disabled" : ""}
					type="filter"
					clickHandler={() => setReportDownloadOpen(true)}
				>
					Filters Applied
				</Button>
			</div>
			<CustomTable
				bordered
				columns={tabularData?.columns || []}
				loading={loading || isLoading}
				tableLoader={true}
				showLoadingTimeAlert={true}
				loadingAlertHeader="Oops! taking longer than expected"
				loadingAlertSubHeader="This might take a moment. Preview will reflect shortly."
				data={tabularData?.rows || []}
				tableOverflow={true}
				classes="report-preview-table-container"
				customPlaceholder={<Placeholder {...PLACEHOLDER} />}
			/>
			<ReportDownload
				isOpen={isReportDownloadOpen}
				close={() => setReportDownloadOpen(false)}
				fromPreview={true}
				match={{ params: { id: match.params.id } }}
			/>
			<ShareOnEmail isOpen={showEmailExport} close={handleCloseEmailExport} fromPreview={true} />
		</div>
	);
};
export default connect((store) => ({
	reportDetail: store.reportDetail,
	reportPreview: store.reportPreview
}))(ReportPreview);
